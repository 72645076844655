import { get } from 'lodash'

import { STATE_ABBREVIATIONS } from '../../../../constants/states'

import NeighborhoodLanguages from './neighborhood-language.json'

const mapData = ({ reportStateShort, neighborhood }) => {
  const language = NeighborhoodLanguages.find(
    lang => lang.State === reportStateShort && lang.Neighborhood === neighborhood
  )

  let generatedText = ''

  if (language) {
    const carLanguage = language['Car Language'] || ''
    const trainLanguage = language['Train Language'] || ''
    const busLanguage = language['Bus Language'] || ''
    const plainLanguage = language['Plane Language'] || ''

    generatedText = [carLanguage, trainLanguage, busLanguage, plainLanguage].join(' ')
  }

  return {
    generatedText,
    hasGeneratedText: !!generatedText,
  }
}

export const mapDTO = formValues => {
  const reportState = formValues.reportState
  const reportStateShort = STATE_ABBREVIATIONS[reportState]
  const neighborhood = formValues.neighborhood

  return mapData({ reportStateShort, neighborhood })
}

export const mapDataModel = report => {
  const reportState = get(report, 'property_information.state')
  const reportStateShort = STATE_ABBREVIATIONS[reportState]
  const neighborhood = get(report, 'property_information.neighborhood')

  return mapData({ reportStateShort, neighborhood })
}
