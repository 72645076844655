import { isEmpty, map, filter, lowerCase } from 'lodash'

import { DEFAULT_READY_FOR_OCCUPANCY } from '../../../constants/report/property'

import { ListBuilder } from '../../../../libs/bowery-libs/export-schemas/helpers'
import { simplePluralize } from '../index'

export const generateUnitInspectionDiscussion = ({ units }) => {
  if (isEmpty(units)) {
    return 'We have not inspected any units.'
  }

  const sentences = [
    `We inspected ${units.length} ${simplePluralize('unit', units.length)}: ${ListBuilder.createFrom(
      map(units, 'number')
    )}.`,
  ]

  filter(units, ['leaseStatus', 'Vacant']).forEach(({ number, condition, readyForOccupancy }) => {
    let unitDescription = `${number} is a vacant unit, that is ${lowerCase(condition)}`
    if (readyForOccupancy !== DEFAULT_READY_FOR_OCCUPANCY) {
      unitDescription += `, and ${lowerCase(readyForOccupancy)}`
    }
    sentences.push(`${unitDescription}.`)
  })

  sentences.push(
    'We assume the units that were not inspected are of generally similar condition to the units inspected.'
  )

  return sentences.join(' ')
}
