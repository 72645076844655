import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { Paper, Grid, Typography, Table, TableHead, TableBody, TableRow } from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'

import AlertInfo from '../../../../../shared/components/AlertInfo'
import { CheckboxWithLabel } from '../../../../../shared/components'

import { getRentPsfLabel } from '../../../../../../../shared/helpers/commercialIncome'
import { formatCurrencyFloat } from '../../../../../shared/utils/numberFormatters'

import { Labels } from './CommercialRentReconciliationConstants'
import { Cell, TotalCell, BaseUnitCell, TotalBaseUnitCell, SubjectUnitCell, TotalSubjectUnitCell } from './TableCells'

import ConditionAdjustments from './Sections/ConditionAdjustments'
import LocationAdjustments from './Sections/LocationAdjustments'
import MarketAdjustments from './Sections/MarketAdjustments'
import MarketRentConclusion from './Sections/MarketRentConclusion'
import SizeAdjustments from './Sections/SizeAdjustments'
import TotalsAndConclusion from './Sections/TotalsAndConclusion'
import UtilityAdjustments from './Sections/UtilityAdjustments'

const styles = theme => ({
  paper: theme.paper,
  checkboxRoot: {
    display: 'inline-flex',
    width: 'initial',
    height: 32,
    marginLeft: theme.spacing.unit * 2,
  },
  checkbox: {
    paddingRight: 4,
  },
})

class CommercialRentReconciliationItem extends React.PureComponent {
  static propTypes = {
    fieldPrefix: PropTypes.string.isRequired,
    reconciliationGroupData: PropTypes.object.isRequired,
    baseUnitTenantName: PropTypes.string,
    baseUnitSquareFeet: PropTypes.number,
    baseUnitPerSFRent: PropTypes.number,
    baseUnitDateSigned: PropTypes.string,
    baseUnitLeaseTerms: PropTypes.string,
    baseUnitFrontage: PropTypes.string,
    baseUnitCeilingHeight: PropTypes.string,
    baseUnitLocation: PropTypes.string,
    units: PropTypes.arrayOf(PropTypes.object).isRequired,
    rentComps: PropTypes.arrayOf(PropTypes.object).isRequired,
    averageAdjustmentRentPSF: PropTypes.number,
    includeInExport: PropTypes.bool.isRequired,
    isGroupValid: PropTypes.bool.isRequired,
    rentRollRentBasis: PropTypes.string.isRequired,
  }

  get rentPsfLabel() {
    const { rentRollRentBasis } = this.props
    return getRentPsfLabel(rentRollRentBasis, Labels.RENT_PER_SF_PER_MONTH, Labels.RENT_PER_SF)
  }

  render() {
    const {
      fieldPrefix,
      reconciliationGroupData,
      baseUnitTenantName,
      baseUnitSquareFeet,
      baseUnitPerSFRent,
      baseUnitDateSigned,
      baseUnitLeaseTerms,
      baseUnitFrontage,
      baseUnitCeilingHeight,
      baseUnitLocation,
      units,
      rentComps,
      averageAdjustmentRentPSF,
      includeInExport,
      isGroupValid,
      rentRollRentBasis,
      classes,
    } = this.props

    const colsCount = 2 + units.length + rentComps.length
    // const colWidth = 100 / colsCount
    const colStyle = {
      width: 50,
    }

    const leaseTermsCalcType = get(reconciliationGroupData, 'summary.leaseTermsCalcType')

    const disableInputs = !includeInExport

    return (
      <Paper className={classes.paper}>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <Typography inline variant="h6">{`${reconciliationGroupData.name} ${Labels.RECONCILIATION}`}</Typography>
            <CheckboxWithLabel name={`${fieldPrefix}.includeInExport`} label="Include adjustment grid in report." />
          </Grid>
          <Grid item xs={12}>
            {isGroupValid ? (
              <Table>
                <TableHead>
                  <TableRow data-qa="head-row">
                    <TotalCell data-qa="comp-head-cell" style={colStyle}>
                      {Labels.COMP}
                    </TotalCell>
                    <TotalBaseUnitCell data-qa="base-unit-head-cell" style={colStyle}>
                      {Labels.BASE_UNIT}
                    </TotalBaseUnitCell>
                    {units.map((unit, index) => (
                      <TotalSubjectUnitCell data-qa={`subject-unit-${index}-head-cell`} key={unit._id} style={colStyle}>
                        {`${Labels.SUBJECT_UNIT} ${index + 1}`}
                      </TotalSubjectUnitCell>
                    ))}
                    {rentComps.map((rentComp, index) => (
                      <TotalCell data-qa={`rent-comp-${index}-head-cell`} key={rentComp._id} style={colStyle}>
                        {index + 1}
                      </TotalCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow data-qa="tenant-name-row">
                    <TotalCell data-qa="row-label-cell">{Labels.TENANT_NAME}</TotalCell>
                    <BaseUnitCell data-qa="base-unit-cell">{baseUnitTenantName}</BaseUnitCell>
                    {units.map((unit, index) => (
                      <SubjectUnitCell data-qa={`subject-unit-${index}-cell`} key={unit._id}>
                        {get(unit, 'tenantName')}
                      </SubjectUnitCell>
                    ))}
                    {rentComps.map((rentComp, index) => (
                      <Cell data-qa={`rent-comp-${index}-cell`} key={rentComp._id}>
                        {get(rentComp, 'tenantName')}
                      </Cell>
                    ))}
                  </TableRow>
                  <TableRow data-qa="rent-per-sf-row">
                    <TotalCell data-qa="row-label-cell">{this.rentPsfLabel}</TotalCell>
                    <BaseUnitCell data-qa="base-unit-cell">{formatCurrencyFloat(baseUnitPerSFRent)}</BaseUnitCell>
                    {units.map((unit, index) => (
                      <SubjectUnitCell data-qa={`subject-unit-${index}-cell`} key={unit._id}>
                        {formatCurrencyFloat(get(unit, 'psfRent'))}
                      </SubjectUnitCell>
                    ))}
                    {rentComps.map((rentComp, index) => (
                      <Cell data-qa={`rent-comp-${index}-cell`} key={rentComp._id}>
                        {formatCurrencyFloat(get(rentComp, 'psfRent'))}
                      </Cell>
                    ))}
                  </TableRow>
                  <MarketAdjustments
                    fieldPrefix={fieldPrefix}
                    baseUnitDateSigned={baseUnitDateSigned}
                    baseUnitPerSFRent={baseUnitPerSFRent}
                    baseUnitLeaseTerms={baseUnitLeaseTerms}
                    units={units}
                    rentComps={rentComps}
                    leaseTermsCalcType={leaseTermsCalcType}
                    rentRollRentBasis={rentRollRentBasis}
                    disableInputs={disableInputs}
                  />
                  <SizeAdjustments
                    fieldPrefix={fieldPrefix}
                    baseUnitSquareFeet={baseUnitSquareFeet}
                    baseUnitPerSFRent={baseUnitPerSFRent}
                    units={units}
                    rentComps={rentComps}
                    disableInputs={disableInputs}
                  />
                  <LocationAdjustments
                    colsCount={colsCount}
                    fieldPrefix={fieldPrefix}
                    reconciliationGroupData={reconciliationGroupData}
                    units={units}
                    rentComps={rentComps}
                    disableInputs={disableInputs}
                  />
                  <UtilityAdjustments
                    fieldPrefix={fieldPrefix}
                    baseUnitFrontage={baseUnitFrontage}
                    baseUnitCeilingHeight={baseUnitCeilingHeight}
                    baseUnitLocation={baseUnitLocation}
                    units={units}
                    rentComps={rentComps}
                    colsCount={colsCount}
                    disableInputs={disableInputs}
                  />
                  <ConditionAdjustments
                    fieldPrefix={fieldPrefix}
                    units={units}
                    rentComps={rentComps}
                    disableInputs={disableInputs}
                  />
                  <TotalsAndConclusion
                    units={units}
                    rentComps={rentComps}
                    baseUnitPerSFRent={baseUnitPerSFRent}
                    averageAdjustmentRentPSF={averageAdjustmentRentPSF}
                    rentRollRentBasis={rentRollRentBasis}
                    disableInputs={disableInputs}
                  />
                  <MarketRentConclusion fieldPrefix={fieldPrefix} units={units} rentCompsCount={rentComps.length} />
                </TableBody>
              </Table>
            ) : (
              <AlertInfo>{Labels.SKIP_THIS_GROUP_BECAUSE_THIS_GROUP_DOES_NOT_HAVE_RENT_COMPS_FOR_COMPARISON}</AlertInfo>
            )}
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

export default withStyles(styles)(CommercialRentReconciliationItem)
