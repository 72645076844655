import { PropertyTypes } from 'shared/constants'
import { SALES_APPROACH_TYPES } from 'shared/constants/salesApproach'

export const ADJUSTMENT_GROUPS = {
  MARKET: 'market',
  LOCATION: 'location',
  UTILITY: 'utility',
  OTHER: 'other',
}

export const DEFAULT_ADJUSTMENT_LABELS = {
  propertyRights: 'Property Rights',
  financingTerms: 'Financing Terms',
  conditionsOfSale: 'Conditions of Sale',
  marketConditions: 'Market Conditions',
  neighborhood: 'Better/Worse Neighborhood',
  locationInNeighborhood: 'Location within Neighborhood',
  commercial: 'Commercial Space Adjustment',
  onsiteParking: 'Onsite Parking',
  corner: 'Corner Adjustment',
  finishes: 'Finishes Adjustment',
  elevator: 'Elevator Adjustment',
  tenantMix: 'Tenant Mix',
  amenities: 'Amenities Adjustment',
  signage: 'Signage',
  airRights: 'Air Rights Adjustment',
  floorAreaRatio: 'Floor Area Ratio',
  size: 'Size Adjustment',
  stabilizationLevel: 'Rent Stabilization Level Adjustment',
  condition: 'Condition Adjustment',
  averageUnitSize: 'Average Unit Size',
}

export const DEFAULT_ADJUSTMENT_ROWS = [
  {
    title: 'Property Rights',
    name: 'propertyRights',
    groupName: ADJUSTMENT_GROUPS.MARKET,
  },
  {
    title: 'Financing Terms',
    name: 'financingTerms',
    groupName: ADJUSTMENT_GROUPS.MARKET,
  },
  {
    title: 'Conditions of Sale',
    name: 'conditionsOfSale',
    groupName: ADJUSTMENT_GROUPS.MARKET,
  },
  {
    title: 'Market Conditions (Time)',
    name: 'marketConditions',
    groupName: ADJUSTMENT_GROUPS.MARKET,
  },
  {
    title: 'Better/Worse Neighborhood',
    name: 'neighborhood',
    groupName: ADJUSTMENT_GROUPS.LOCATION,
  },
  {
    title: 'Location within Neighborhood',
    name: 'locationInNeighborhood',
    groupName: ADJUSTMENT_GROUPS.LOCATION,
  },
  {
    title: 'Onsite Parking',
    name: 'onsiteParking',
    groupName: ADJUSTMENT_GROUPS.UTILITY,
  },
  {
    title: 'Corner Adjustment',
    name: 'corner',
    groupName: ADJUSTMENT_GROUPS.UTILITY,
  },
  {
    title: 'Finishes Adjustment',
    name: 'finishes',
    groupName: ADJUSTMENT_GROUPS.UTILITY,
  },
  {
    title: 'Elevator Adjustment',
    name: 'elevator',
    groupName: ADJUSTMENT_GROUPS.UTILITY,
  },
  {
    title: 'Tenant Mix',
    name: 'tenantMix',
    groupName: ADJUSTMENT_GROUPS.UTILITY,
  },
  {
    title: 'Amenities Adjustment',
    name: 'amenities',
    groupName: ADJUSTMENT_GROUPS.UTILITY,
  },
  {
    title: 'Signage',
    name: 'signage',
    groupName: ADJUSTMENT_GROUPS.UTILITY,
  },
  {
    title: 'Air Rights Adjustment',
    name: 'airRights',
    groupName: ADJUSTMENT_GROUPS.UTILITY,
  },
  {
    title: 'Floor Area Ratio',
    name: 'floorAreaRatio',
    groupName: ADJUSTMENT_GROUPS.UTILITY,
  },
  {
    title: 'Commercial Space Adjustment',
    name: 'commercial',
    groupName: ADJUSTMENT_GROUPS.OTHER,
  },
  {
    title: 'Size Adjustment',
    name: 'size',
    groupName: ADJUSTMENT_GROUPS.OTHER,
  },
  {
    title: 'Rent Stabilization Level Adjustment',
    name: 'stabilizationLevel',
    groupName: ADJUSTMENT_GROUPS.OTHER,
  },
  {
    title: 'Condition Adjustment',
    name: 'condition',
    groupName: ADJUSTMENT_GROUPS.OTHER,
  },
  {
    title: 'Average Unit Size',
    name: 'averageUnitSize',
    groupName: ADJUSTMENT_GROUPS.OTHER,
  },
]

export const OTHER_ADJUSTMENT_ROW_KEYS = {
  utility: 'utilityAdjustment.otherAdjustment',
  stabilization: 'stabilizationLevel',
  other: 'otherAdjustment',
}

export const MARKET_ROWS = ['propertyRights', 'financingTerms', 'conditionsOfSale', 'marketConditions']
export const LOCATION_ROWS = ['neighborhood', 'locationInNeighborhood']
export const UTILITY_ROWS = {
  default: ['corner', 'finishes', 'elevator', 'amenities', 'airRights'],
  [PropertyTypes.COMMERCIAL]: ['onsiteParking', 'corner', 'finishes', 'tenantMix', 'signage', 'floorAreaRatio'],
}

export const OTHER_ROWS = {
  [SALES_APPROACH_TYPES.IMPROVED]: ['size', 'stabilizationLevel', 'condition', 'commercial', 'averageUnitSize'],
  [SALES_APPROACH_TYPES.LAND]: ['size'],
}

export const getDefaultRows = (isImprovedSales, isCommercialProperty) => {
  return {
    market: MARKET_ROWS,
    location: LOCATION_ROWS,
    utility: isCommercialProperty ? UTILITY_ROWS.commercial : UTILITY_ROWS.default,
    other: isImprovedSales ? OTHER_ROWS.improved : OTHER_ROWS.land,
  }
}
export const CUSTOM_ADJUSTMENT_GROUPS = [ADJUSTMENT_GROUPS.UTILITY, ADJUSTMENT_GROUPS.OTHER]

// maintaining these for data-qa path
export const OTHER_ADJUSTMENT_PATH = {
  utility: {
    label: 'utilityAdjustmentLabel',
  },
  other: {
    label: 'otherAdjustmentLabel',
  },
}
