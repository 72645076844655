import { objectToKeyValuePairs } from '../../../../../shared/utils/ui/checkboxHelper'

export const Labels = {
  COMMERCIAL_RENT_ROLL: 'In-Place Rent Roll',
}

export const LeaseStatusTypes = {
  Occupied: 'Occupied',
  Vacant: 'Vacant',
}

export const LEASE_STATUS_OPTIONS = objectToKeyValuePairs(LeaseStatusTypes)
