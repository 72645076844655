import { difference } from 'lodash'
import arrayToSentence from 'array-to-sentence'
import indefiniteArticle from 'indefinite-article'

import { articleGen } from 'shared/utils/formatters/textFormatters'
import { OWNER_USER, PROPERTY_TYPES } from 'shared/constants/report/final/highestAndBestUse'

import { SF_TO_ACRE_RATIO } from 'report/constants'

import { getZonesAllowableUses } from 'shared/utils/report/propertyInformation/zoning'

import { simplePluralize as pluralize } from '../../../../../../../shared/utils/textGeneration'
import { MID_BLOCK, SITE_AREA_SF_UNIT_OF_MEASURE } from '../../../../../../../shared/constants/report'
import { formatInt, formatFloat } from '../../../../../shared/utils/numberFormatters'
import { formatList } from '../../../../../shared/utils/textHelpers'
import { formatSiteArea } from '../../../../../../../shared/utils/formatters'
import { SiteAreaLabels } from '../../constants'
import { PropertyTypes as PT } from '../../../../../../../shared/constants'

const generateStreetFrontageSentence = (frontage, streetName) => {
  return `${formatInt(frontage)} feet of frontage along ${streetName}`
}

export const generateLegallyPermissibleAsImprovedCommentary = props => {
  const { form, siteArea, siteAreaMeasure, isComplying, propertyType, grossBuildingArea } = props
  const { zones } = form.values
  const zonesFormatted = formatList(zones) || '[ZONE NAME]'
  const allowableUses = formatList(getZonesAllowableUses(zones)) || '[ALLOWABLE USE]'
  const siteAreaText = formatSiteArea({
    siteArea,
    siteAreaMeasure,
    labelTypeOverride: { sf: 'square feet', acre: 'acre' },
  })
  const siteAreaSF = siteAreaMeasure === SITE_AREA_SF_UNIT_OF_MEASURE ? siteArea : siteArea / SF_TO_ACRE_RATIO
  const effectiveFloorAreaRatio = formatFloat(grossBuildingArea / siteAreaSF)
  const isComplyingText = isComplying
    ? `a permitted use. The improvements comply to bulk requirements as specified and discussed ` +
      `in the zoning section of this report and represent a legal use of the site.`
    : `not a permitted use. The improvements do not comply to the bulk requirements as specified ` +
      `and discussed in the zoning section of the report. Based on the age of the improvements and ` +
      `our zoning analysis, we determine the existing improvements represent a pre-existing, non-conforming use.`
  return (
    `The subject improvements consist of ${formatInt(grossBuildingArea)} square feet of gross building area on a ` +
    `${siteAreaText} site which reflect an FAR of ${effectiveFloorAreaRatio}. Located in the ${zonesFormatted} ` +
    `${pluralize('zone', zones.length)}, permitted uses include ${allowableUses}. The current ${propertyType} ` +
    `use of the improvements is ${isComplyingText}`
  )
}

const generatePreclusionsSentence = props => {
  const {
    geographySpecific: { preclusionsExist, preclusions },
  } = props.form.values
  const mapPreclusion = value => {
    const mapping = {
      zoningOrdinance: 'zoning ordinance changes',
      easementsAndEncroachments: 'known easements and encroachments',
      rentRegulatedTenants: 'rent regulated tenants with indefinite tenancy rights',
    }
    return mapping[value] || ''
  }
  let preclusionsSentence = ''

  if (!preclusionsExist) {
    preclusionsSentence =
      'There are no zoning changes anticipated and no easements or encroachments that preclude development.'
  } else if (preclusions.length === 1) {
    switch (preclusions[0]) {
      case 'zoningOrdinance':
        preclusionsSentence = 'There is a zoning change anticipated.'
        break
      case 'easementsAndEncroachments':
        preclusionsSentence = 'There are known easements and encroachments that could preclude development.'
        break
      case 'rentRegulatedTenants':
        preclusionsSentence =
          'There are existing rent regulated tenants that have indefinite tenancy rights that could hinder development.'
        break
      default:
        break
    }
  } else {
    const preclusionsList = preclusions.length === 0 ? 'preclusions' : formatList(preclusions.map(mapPreclusion))
    preclusionsSentence = `There are ${preclusionsList} that could hinder development.`
  }

  return preclusionsSentence
}

export const generateLegallyPermissibleAsVacantCommentary = props => {
  const { form, floorAreaRatio, maxBuildableArea, siteArea, siteAreaMeasure, commercialOverlays, allowableUses } = props
  const { zones } = form.values
  const zonesFormatted = formatList(zones) || '[ZONE NAME]'
  const formattedAllowableUses = formatList(allowableUses) || '[ALLOWABLE USE]'
  const siteAreaText = formatSiteArea({ siteArea, siteAreaMeasure })
  const maxBuildableAreaText = `${formatInt(maxBuildableArea)} ${SiteAreaLabels[siteAreaMeasure]}`
  const preclusionsSentence = generatePreclusionsSentence(props)

  const commercialOverlaysList = formatList(commercialOverlays)
  const commercialOverlaysFragment = commercialOverlays.length
    ? ` as well as commercial uses due to the ${commercialOverlaysList} commercial ${pluralize(
        'overlay',
        commercialOverlays.length
      )}`
    : ''

  return (
    `The subject is in ${pluralize('zone', zones.length)} ${zonesFormatted}, ` +
    `which permit${zones.length === 1 ? 's' : ''} ${formattedAllowableUses} ` +
    `use as of right${commercialOverlaysFragment}. Based on the maximum effective FAR of ${floorAreaRatio} ` +
    `and a lot area of ${siteAreaText}, ${maxBuildableAreaText} of max buildable area ` +
    `is permitted on site. ${preclusionsSentence}`
  )
}

export const generateAsVacantPhysicallyPossibleCommentary = props => {
  const { form, siteArea, siteAreaMeasure, streetName } = props
  const { sizeWithinRange, necessaryUtilitiesAvailable, frontage, streetsFrontage, corner } = form.values
  const siteAreaFormatted = siteAreaMeasure === SITE_AREA_SF_UNIT_OF_MEASURE ? formatInt(siteArea) : siteArea
  const siteAreaText = `${siteAreaFormatted} ${SiteAreaLabels[siteAreaMeasure]}`
  const utilitiesSentence = necessaryUtilitiesAvailable
    ? 'All necessary utilities are available and the site appears functional for a variety of permitted uses.'
    : 'The necessary utilities are not available.'

  const formattedFrontages = []

  if (corner === MID_BLOCK) {
    if (frontage && streetName) {
      formattedFrontages.push(generateStreetFrontageSentence(frontage, streetName))
    }
  } else {
    if (streetsFrontage.street1Frontage && streetsFrontage.street1Name) {
      formattedFrontages.push(
        generateStreetFrontageSentence(streetsFrontage.street1Frontage, streetsFrontage.street1Name)
      )
    }

    if (streetsFrontage.street2Frontage && streetsFrontage.street2Name) {
      formattedFrontages.push(
        generateStreetFrontageSentence(streetsFrontage.street2Frontage, streetsFrontage.street2Name)
      )
    }
    if (streetsFrontage.street3Frontage && streetsFrontage.street3Name) {
      formattedFrontages.push(
        generateStreetFrontageSentence(streetsFrontage.street3Frontage, streetsFrontage.street3Name)
      )
    }
  }

  return `The site contains ${siteAreaText}${
    !!formattedFrontages.length ? ` with ${arrayToSentence(formattedFrontages)}` : ''
  }. The size ${
    sizeWithinRange ? 'falls' : 'does not fall'
  } within the range of improved sites in the area. ${utilitiesSentence}`
}

export const generateAsImprovedPhysicallyPossibleCommentary = props => {
  const { currentBuildingDescription, prospectiveBuildingDescription, isAsComplete, propertyType } = props

  return (
    `${currentBuildingDescription} ${isAsComplete ? prospectiveBuildingDescription : ''}The layout, site ` +
    `coverage and positioning of the improvements are considered functional for the current use. While it ` +
    `would be physically possible for a wide variety of uses, based on legal restrictions in place and ` +
    `the design of the improvements, continued use of the property as ${propertyType} ` +
    `use is considered the most functional. Additionally, there is no excess or surplus land noted nor ` +
    `excess FAR to consider.`
  )
}

const formatFeasiblePropertyTypes = (list, options) => {
  const updatedList = list.map(type => (PROPERTY_TYPES[type] || type).toLowerCase())
  return formatList(updatedList, options)
}

export const generateAsVacantFinanciallyFeasibleCommentary = props => {
  const {
    subjectMarketCharacteristics,
    asVacantBestUsePropertyType,
    asVacantFeasiblePropertyTypes,
    newConstructionFeasible,
  } = props.form.values
  const marketCharacteristics = subjectMarketCharacteristics.toLowerCase()
  const bestUsePropertyTypeFormatted = asVacantBestUsePropertyType.toLowerCase()

  const feasiblePropertyTypesFormatted = formatFeasiblePropertyTypes(asVacantFeasiblePropertyTypes)

  const propertyTypes = feasiblePropertyTypesFormatted || bestUsePropertyTypeFormatted
  const propertyTypesFormatted = feasiblePropertyTypesFormatted || `${bestUsePropertyTypeFormatted} property`
  const notFeasiblePropertyTypesFormatted = bestUsePropertyTypeFormatted
    ? `${bestUsePropertyTypeFormatted} buildings`
    : `${feasiblePropertyTypesFormatted} properties`

  let newConstructionPart = `New construction in the neighborhood is currently underway`
  if (newConstructionFeasible) {
    newConstructionPart +=
      ` and newly developed ${feasiblePropertyTypesFormatted} buildings in the subject's submarket` +
      ` are selling, an indication of feasibility.`
  } else {
    // eslint-disable-next-line max-len
    newConstructionPart += ` but limited, an indication that developing new ${notFeasiblePropertyTypesFormatted} is not feasible.`
  }

  return (
    `The subject is located within a primarily ${marketCharacteristics} neighborhood. Based on our analysis ` +
    `of the market, there is sufficient demand for ${propertyTypes} properties. Market conditions${
      newConstructionFeasible ? '' : ', though,'
    } are such that new ${propertyTypesFormatted} construction is ${
      newConstructionFeasible ? 'feasible' : 'not feasible'
    }, as the value ${
      newConstructionFeasible ? 'would' : 'would not'
    } sufficiently exceed the cost plus developer's profit. ${newConstructionPart}`
  )
}

export const generateAsImprovedFinanciallyFeasibleCommentary = props => {
  const { form, isAsComplete } = props
  const {
    subjectMarketCharacteristics,
    occupancyLevel,
    asImprovedBestUsePropertyType,
    asImprovedFeasiblePropertyTypes,
    demolitionFeasible,
  } = form.values
  const marketCharacteristics = subjectMarketCharacteristics.toLowerCase()
  const occupancyRate = Math.round(100 * occupancyLevel)
  const bestUsePropertyTypeFormatted = asImprovedBestUsePropertyType.toLowerCase()
  const feasiblePropertyTypesFormatted = formatFeasiblePropertyTypes(asImprovedFeasiblePropertyTypes)
  const propertyTypesFormatted = feasiblePropertyTypesFormatted || `${bestUsePropertyTypeFormatted}`

  const subjectReflect = isAsComplete
    ? 'Upon renovation, the subject will reflect 100% occupancy and will generate a positive net cash flow.'
    : `As improved, the subject reflects ${occupancyRate}% occupancy and will generate a positive net cash flow.`

  return (
    `The subject property is located within a primarily ${marketCharacteristics} neighborhood ` +
    `exhibiting low vacancy rates and increasing rental rates. ${subjectReflect} ` +
    `Therefore, use as ${articleGen(
      propertyTypesFormatted
    )} ${propertyTypesFormatted} property is financially feasible. ` +
    `Also, ${demolitionFeasible ? 'demolition is an option.' : 'demolition is not an option.'}`
  )
}

export const generateAsVacantMaximallyProductiveCommentary = props => {
  const { newConstructionFeasible, asVacantBestUsePropertyType, asVacantFeasiblePropertyTypes } = props.form.values
  const bestUsePropertyTypeFormatted = asVacantBestUsePropertyType.toLowerCase()
  const feasiblePropertyTypesFormatted = formatFeasiblePropertyTypes(asVacantFeasiblePropertyTypes, { or: true })
  const propertyTypesFormatted = feasiblePropertyTypesFormatted || bestUsePropertyTypeFormatted

  return (
    `There does not appear to be any reasonably probable use of the site that would generate a higher residual` +
    ` land value than to ${
      newConstructionFeasible ? 'develop for' : 'hold for development of'
    } ${propertyTypesFormatted} use. Based on the normal market` +
    ` density level permitted by zoning, this is considered the maximally productive use of the site.`
  )
}

export const generateAsImprovedMaximallyProductiveCommentary = props => {
  const { form, isAsComplete } = props
  const { asImprovedBestUsePropertyType, asImprovedFeasiblePropertyTypes } = form.values
  const bestUsePropertyTypeFormatted = asImprovedBestUsePropertyType.toLowerCase()
  const feasiblePropertyTypesFormatted = formatFeasiblePropertyTypes(asImprovedFeasiblePropertyTypes)
  const propertyTypesFormatted = feasiblePropertyTypesFormatted || bestUsePropertyTypeFormatted

  return (
    `There does not appear to be any alternative use that could reasonably be expected to provide ` +
    `a higher present value than the current use, and the existing use exceeds the value of the site as vacant. ` +
    `Continued ${propertyTypesFormatted} use${
      isAsComplete ? ' upon completion of the proposed construction and renovations' : ''
    } is concluded to be maximally productive.`
  )
}

export const generateAsVacantConclusionCommentary = props => {
  const { asVacantBestUsePropertyType, asVacantFeasiblePropertyTypes, newConstructionFeasible } = props.form.values
  const bestUsePropertyTypeFormatted = asVacantBestUsePropertyType.toLowerCase()
  const feasiblePropertyTypesFormatted = formatFeasiblePropertyTypes(asVacantFeasiblePropertyTypes, { or: true })
  const propertyTypesFormatted = feasiblePropertyTypesFormatted || bestUsePropertyTypeFormatted

  return (
    `Based on the subject property's zoning, physical characteristics, location, and forecasted economic conditions, ` +
    `it is our opinion that the highest and best use of the site as vacant is ${
      newConstructionFeasible ? 'to develop' : 'to hold for development of'
    } ${articleGen(propertyTypesFormatted)} ${propertyTypesFormatted} building to the highest density ` +
    'permitted by zoning and supported within the market.'
  )
}

export const generateAsImprovedConclusionCommentary = props => {
  const { asImprovedBestUsePropertyType = '', asImprovedFeasiblePropertyTypes } = props.form.values
  const bestUsePropertyTypeFormatted = asImprovedBestUsePropertyType.toLowerCase()
  const feasiblePropertyTypesFormatted = formatFeasiblePropertyTypes(asImprovedFeasiblePropertyTypes, { or: true })
  const propertyTypesFormatted = feasiblePropertyTypesFormatted || bestUsePropertyTypeFormatted

  return (
    `Based on the subject property's zoning, physical characteristics, location, and forecasted economic conditions, ` +
    `continued ${propertyTypesFormatted} use is concluded to be the highest and best use as improved.`
  )
}

export const generateMostProbableBuyerCommentary = props => {
  const { mostProbableBuyer } = props.form.values
  const { propertyType } = props

  const probableBuyersList = []

  const investorDevelopers = difference(mostProbableBuyer, [OWNER_USER])
  if (investorDevelopers.length) {
    probableBuyersList.push(`${formatList(investorDevelopers)} investor/developer`)
  }

  if (mostProbableBuyer.includes(OWNER_USER)) {
    probableBuyersList.push('owner-user')
  }

  const probableBuyerFormatted = formatList(probableBuyersList)
  const probableBuyerArticle = indefiniteArticle(probableBuyerFormatted)
  let incomeType = PT.MULTI_FAMILY
  if (propertyType === PT.COMMERCIAL) {
    incomeType = PT.COMMERCIAL
  }

  // eslint-disable-next-line max-len
  return `Taking into account the size and characteristics of the property and its ${incomeType} occupancy, the likely buyer is ${probableBuyerArticle} ${probableBuyerFormatted}.`
}
