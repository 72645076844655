import { GeneratedText, gtLocked, gtTemplate, addConditionals, gtIfElse } from '../../../narrative/GeneratedText'
import { simplePluralize as pluralize, pluralizeBeVerb } from '../../index'

import { mapDTO, mapDataModel } from './mappings'

const generate = values => {
  const { unitsCount, occupiedUnitLeasesWord, vacantUnitsWord, vacantUnitTermsWord, vacantUnitsVerb } = values
  const unitCountGt = gtLocked('unitsCount', null, 'Unit count')
  const unitsWord = pluralize('unit', unitsCount)
  const termsWord = pluralize('term', unitsCount)
  const unitsVerb = pluralizeBeVerb(unitsCount)

  const gt = new GeneratedText()

  gt.t`${gtIfElse(
    'mentionRenovations',
    gtTemplate`Upon completion of the renovations, the subject will contain ${unitCountGt} commercial ${unitsWord}.`,
    gtTemplate`The subject contains ${unitCountGt} commercial ${unitsWord}.`
  )}`

  addConditionals(gt, {
    isVacantUnits: [
      ...gtTemplate` Commercial ${gtLocked('vacantUnitsWord', null, 'Units word')} ${gtLocked(
        'vacantUnitsList',
        null,
        'Number of vacant units'
      )} ${gtLocked('vacantUnitsVerb', null, 'Vacant units verb')} currently vacant.
      We have forecasted market rent and market lease terms for the vacant ${vacantUnitsWord} based on the occupied
      unit ${occupiedUnitLeasesWord}, the comparables above, and our research of the subject's market.
      The forecasted lease ${vacantUnitTermsWord} ${vacantUnitsVerb} summarized below.`,
    ],
    noVacantUnits: [...gtTemplate` The ${termsWord} ${unitsVerb} summarized below.`],
  })

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
