import BoweryDate from '@bowery-valuation/bowery-date'
import { ColumnDataTypeEnum } from '@bowery-valuation/ui-components'
import { formatCurrencyInt } from 'client-shared/utils/numberFormatters'
import { startCase, toNumber } from 'lodash'
import { SALES_APPROACH_TYPES, UNIT_OF_COMPARISON_TYPES } from 'shared/constants/salesApproach'
import { ADJUSTMENT_GROUPS } from 'shared/helpers/salesApproach/salesCompAdjustmentConstants'
import { formatFloat, formatInt, formatPercentageString } from 'shared/utils/formatters/numberFormatters'
import { divide } from 'shared/utils/numberOperations'
import { formatList } from 'shared/utils/textHelpers'

export const EXPANDED_TABLE_TITLES = {
  saleDate: 'Sale Date',
  salePrice: 'Sale Price',
  capRate: 'Cap Rate',
  pricePerUnit: 'Price per Unit',
  pricePerSF: 'Price per SF',
  propertyDescription: 'Property Description',
  propertyRights: 'Property Rights',
  neighborhood: 'Neighborhood',
  buildingType: 'Building Type',
  corner: 'Corner?',
  elevatored: 'Elevatored',
  airRights: 'Unused Air Rights',
  commercialUnits: '# of Commercial Units',
  commercialAreaRatio: 'Commercial Area/SF',
  squareFootage: 'SF',
  residentialUnits: '# of Residential Units',
  incomeLevel: 'Income Level Adjusted',
  yearBuilt: 'Year Built',
  city: 'City',
  condition: 'Condition',
}

export const ExpandedMarketRows = [
  'saleDate',
  'salePrice',
  'capRate',
  'pricePerUnit',
  'pricePerSF',
  'propertyRights',
  'propertyDescription',
]

export const ExpandedLocationRows = ['neighborhood', 'city']

export const ExpandedUtilityRows = [
  'buildingType',
  'corner',
  'elevatored',
  'airRights',
  'commercialUnits',
  'commercialAreaRatio',
]

export const ExpandedOtherRows = {
  [SALES_APPROACH_TYPES.IMPROVED]: ['squareFootage', 'residentialUnits', 'incomeLevel', 'condition', 'yearBuilt'],
  [SALES_APPROACH_TYPES.LAND]: ['squareFootage', 'residentialUnits'],
}

export const ExpandedRowKeys = {
  saleDate: 'saleDate',
  salePrice: 'salePrice',
  capRate: 'capRate',
  pricePerUnit: 'pricePerUnit',
  pricePerSF: 'pricePerSF',
  propertyRights: 'propertyRights',
  propertyDescription: 'propertyDescription',
  neighborhood: 'neighborhood',
  buildingType: 'buildingType',
  corner: 'corner',
  elevatored: 'elevatored',
  airRights: 'airRights',
  commercialUnits: 'commercialUnits',
  commercialAreaRatio: 'commercialAreaRatio',
  squareFootage: 'squareFootage',
  residentialUnits: 'residentialUnits',
  incomeLevel: 'incomeLevel',
  yearBuilt: 'yearBuilt',
  city: 'city',
  condition: 'condition',
}

export const EXPANSION_PANELS = {
  market: ADJUSTMENT_GROUPS.MARKET,
  location: ADJUSTMENT_GROUPS.LOCATION,
  utility: ADJUSTMENT_GROUPS.UTILITY,
  other: ADJUSTMENT_GROUPS.OTHER,
}

export const TOOLTIP_TEXT = {
  MARKET: 'View Market Details',
  LOCATION: 'View Location Details',
  UTILITY: 'View Utility Details',
  OTHER: 'View Other Details',
}

export const MARKET_CONDITION_ADJUSTMENT_TOOLTIP =
  'For As Is properties this will calculate a market condition adjustment based on (Subject Date of Value - Comp Date of Sale) / 365 x % Market Condition Adjustment. For Stabilized properties, this will calculate a market condition adjustment based on (Subject Date of Stabilization - Comp Date of Sale) / 365 x % Market Condition Adjustment.'

export const CONDITION_ADJUSTMENT_MAP = {
  excellent: 10,
  good: 9,
  average: 8,
  satisfactory: 7,
  fair: 6,
  poor: 5,
  underRenovation: 4,
  shell: 3,
}

export const getCompInformationRows = (subjectPropertyInformation, unitOfComparison) => {
  return [
    {
      readOnly: false,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'saleInformation.saleDate',
      formatter: date => new BoweryDate(date).formatShortDate(),
      label: 'Sale Date',
      rowDef: { hideAction: true },
      subject: '',
    },
    {
      readOnly: false,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'saleInformation.salePrice',
      formatter: formatCurrencyInt,
      label: 'Sale Price',
      rowDef: { hideAction: true },
      subject: '',
    },
    {
      readOnly: false,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'saleInformation.capRate',
      formatter: formatPercentageString,
      label: 'Cap Rate',
      rowDef: { hideAction: true },
    },
    {
      readOnly: false,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'saleInformation.propertyRights',
      label: 'Property Rights',
      formatter: startCase,
      rowDef: { hideAction: true },
    },
    {
      readOnly: false,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'address.city',
      label: 'City',
      rowDef: { hideAction: true },
      subject: subjectPropertyInformation.city,
    },
    {
      readOnly: false,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'propertyInformation.market',
      label: 'Market',
      rowDef: { hideAction: true },
      subject: subjectPropertyInformation.market,
    },
    {
      readOnly: false,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'propertyInformation.submarket',
      label: 'Submarket',
      rowDef: { hideAction: true },
      subject: subjectPropertyInformation.submarket,
    },
    {
      readOnly: false,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'propertyInformation.residentialUnits',
      label: '# of Residential Units',
      rowDef: { hideAction: true },
      subject: subjectPropertyInformation.residentialUnits,
    },
    {
      readOnly: false,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'propertyInformation.commercialUnits',
      label: '# of Commercial Units',
      rowDef: { hideAction: true },
      subject: subjectPropertyInformation.commercialUnits,
    },
    {
      readOnly: false,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'propertyInformation.averageUnitSize',
      formatter: formatInt,
      label: 'Average Unit Size',
      rowDef: { hideAction: true },
      subject: formatInt(subjectPropertyInformation.averageUnitSize),
    },
    {
      readOnly: false,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'propertyInformation.grossBuildingArea',
      formatter: formatInt,
      label: 'GBA',
      rowDef: { hideAction: true },
      subject: formatInt(subjectPropertyInformation.grossBuildingArea),
    },
    {
      readOnly: false,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'propertyInformation.condition',
      label: 'Condition',
      formatter: startCase,
      rowDef: { hideAction: true },
      subject: subjectPropertyInformation.condition,
    },
    {
      readOnly: false,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'propertyInformation.yearBuilt',
      label: 'Year Built',
      rowDef: { hideAction: true },
      subject: subjectPropertyInformation.yearBuilt,
    },
    {
      readOnly: false,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'propertyInformation.propertyType',
      label: 'Property Type',
      formatter: startCase,
      rowDef: { hideAction: true },
      subject: startCase(subjectPropertyInformation.propertyType),
    },
    {
      readOnly: false,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'propertyInformation.buildingType',
      label: 'Elevatored',
      formatter: startCase,
      rowDef: { hideAction: true },
      subject: startCase(subjectPropertyInformation.buildingType),
    },
    {
      readOnly: false,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'propertyInformation.parking',
      label: 'Parking',
      subject: subjectPropertyInformation.parking,
      rowDef: { hideAction: true },
    },
    {
      readOnly: false,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'parkingRatio',
      label: 'Parking Ratio',
      subject: getParkingRatio(subjectPropertyInformation, unitOfComparison),
      rowDef: { hideAction: true },
    },
    {
      readOnly: false,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'propertyInformation.buildingAmenities',
      formatter: amenities => {
        const formattedAmenities = amenities.map(amenity => startCase(amenity))
        return formatList(formattedAmenities)
      },
      label: 'Amenities',
      rowDef: { hideAction: true },
    },
    {
      readOnly: false,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'propertyInformation.occupancy',
      formatter: occupancy => (occupancy ? `${occupancy}%` : ''),
      label: 'Occupancy',
      rowDef: { hideAction: true },
    },
    {
      readOnly: false,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'propertyInformation.landToBuildingRatio',
      label: 'Land To Building Ratio',
      rowDef: { hideAction: true },
    },
  ]
}

export const getParkingRatio = (propertyInformation, unitOfComparison) => {
  const { parking, residentialUnits = 0, commercialUnits = 0, grossBuildingArea } = propertyInformation
  const parkingSpaces = toNumber(parking) || 0
  if (!parkingSpaces) {
    return null
  }

  if (unitOfComparison === UNIT_OF_COMPARISON_TYPES.RESIDENTIAL_UNITS) {
    return `${formatFloat(divide(parkingSpaces, residentialUnits), 2)} per unit`
  } else if (unitOfComparison === UNIT_OF_COMPARISON_TYPES.TOTAL_UNITS) {
    const totalUnits = residentialUnits + commercialUnits
    return `${formatFloat(divide(parkingSpaces, totalUnits), 2)} per unit`
  } else {
    const gbaPerThousand = divide(grossBuildingArea, 1000)
    return `${formatFloat(divide(parkingSpaces, gbaPerThousand), 2)} per 1,000 SF`
  }
}
