import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@mui/material'

import { COMMERCIAL_RENT_ROLL_PATH } from 'shared/constants/report/keysAndDataPaths'

import {
  Card,
  CardBody,
  CardContainer,
  CardHeader,
  Label,
  Stack,
  Tooltip,
} from '../../../../../../shared/components/_mui5'
import { ToggleButton } from '../../../../../../shared/components/Fields'
import { Information } from '../../../../../../shared/components/_mui5/Icons'

import wrapForm from '../../../../wrapForm'
import { RENT_TYPES_LIST } from '../../../../../constants/commercial'
import { Labels } from '../constants'
import { rentBasisDecorator } from '../decorators'

import RentRollTable from './RentRollTable'
import CurrentIncomeDiscussion from './CurrentIncomeDiscussion'

export const DATA_PATH = COMMERCIAL_RENT_ROLL_PATH

const CommercialRentRoll = ({ form, classes }) => {
  return (
    <CardContainer>
      <Card>
        <CardHeader title="Commercial In-Place Rent Roll Table" sx={{ mr: 'auto' }}>
          <Stack direction="row" spacing={1} alignItems="baseline" sx={{ mx: 2 }}>
            <Label>Basis of Rent</Label>
            <Tooltip title="Select the unit of the provided rent roll." placement="top">
              <Information />
            </Tooltip>
          </Stack>
          <ToggleButton name="rentBasis" options={RENT_TYPES_LIST} variant="default" />
        </CardHeader>
        <CardBody fluid={true}>
          <RentRollTable form={form} />
        </CardBody>
      </Card>
      <Box p={2}>
        <CurrentIncomeDiscussion form={form} />
      </Box>
    </CardContainer>
  )
}

CommercialRentRoll.propTypes = {
  classes: PropTypes.object.isRequired,
}

const formOptions = {
  heading: Labels.COMMERCIAL_RENT_ROLL,
  subscription: {
    dirty: true,
    invalid: true,
    hasValidationErrors: true,
  },
  decorators: [rentBasisDecorator],
}

export default wrapForm(DATA_PATH, formOptions)(React.memo(CommercialRentRoll))
