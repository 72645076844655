import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { CompPlexEvents } from 'client-shared/constants/compPlex'
import {
  EDIT_COMP_SOURCE_LOCATION,
  EDIT_COMP_SOURCE_UPDATE_FAILED,
} from 'report/forms/sales/SalesCompsSearch/SalesCompsSearchContainer'
import { mapCompPlexSalesCompToCapRateComp } from 'report/forms/final/CapRateComps/helpers'
import { areSameComps } from 'report/forms/sales/SalesCompsSearch/tools'

import { saveReport } from '../../../redux/actions/report'

class EditSalesComp extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    saveReport: PropTypes.func.isRequired,
    formPath: PropTypes.string.isRequired,

    selectedComps: PropTypes.array.isRequired,
    compPlexRef: PropTypes.object.isRequired,
    setEditCompProps: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    removeComp: PropTypes.func.isRequired,
    getCompIdToEditFromLocation: PropTypes.func.isRequired,
    getLocalCompToEditFromLocation: PropTypes.func.isRequired,
  }

  state = {
    initialLocalComp: null,
  }

  componentDidMount() {
    const { compPlexRef } = this.props
    if (compPlexRef.current) {
      compPlexRef.current.addEventListener(CompPlexEvents.EDIT_COMP_CLOSED, this.handleEditModalClosed)
      compPlexRef.current.addEventListener(CompPlexEvents.EDIT_COMP_COMPLETED, this.handleEditModalCompleted)
      compPlexRef.current.addEventListener(CompPlexEvents.EDIT_COMP_SELECT_TOGGLED, this.handleEditModalSelectToggled)
      compPlexRef.current.addEventListener(CompPlexEvents.EDIT_COMP_UPDATED, this.handleEditModalUpdated)
      compPlexRef.current.addEventListener(CompPlexEvents.EDIT_COMP_UPDATE_FAILED, this.handleEditModalUpdateFailed)
    }
  }

  componentWillUnmount() {
    const { compPlexRef } = this.props
    if (compPlexRef.current) {
      compPlexRef.current.removeEventListener(CompPlexEvents.EDIT_COMP_CLOSED, this.handleEditModalClosed)
      compPlexRef.current.removeEventListener(CompPlexEvents.EDIT_COMP_COMPLETED, this.handleEditModalCompleted)
      compPlexRef.current.removeEventListener(
        CompPlexEvents.EDIT_COMP_SELECT_TOGGLED,
        this.handleEditModalSelectToggled
      )
      compPlexRef.current.removeEventListener(CompPlexEvents.EDIT_COMP_UPDATED, this.handleEditModalUpdated)
      compPlexRef.current.removeEventListener(CompPlexEvents.EDIT_COMP_UPDATE_FAILED, this.handleEditModalUpdateFailed)
    }
  }

  componentDidUpdate(prevProps) {
    const { location, getCompIdToEditFromLocation, getLocalCompToEditFromLocation } = this.props

    if (location !== prevProps.location) {
      const navigatedToEditModal = !!getCompIdToEditFromLocation()
      if (navigatedToEditModal) {
        const isCompToEditValid = !!getLocalCompToEditFromLocation()
        if (!isCompToEditValid) {
          this.navigateAwayFromEditModal()
        }
      }
    }
  }

  navigateAwayFromEditModal = () => {
    const {
      history,
      match: { path },
      getCompIdToEditFromLocation,
    } = this.props

    if (getCompIdToEditFromLocation()) {
      history.push(path, { skipSave: true })
    }
  }

  handleEditModalClosed = event => {
    const { source } = event.detail
    if (source === EDIT_COMP_SOURCE_LOCATION) {
      this.navigateAwayFromEditModal()
    } else if (source === EDIT_COMP_SOURCE_UPDATE_FAILED) {
      this.hideEditModal()
    }
  }

  showEditModal = (editCompProps, initialLocalComp) => {
    this.setState({ initialLocalComp })
    this.props.setEditCompProps(editCompProps)
  }

  hideEditModal = () => {
    this.setState({ initialLocalComp: null })
    this.props.setEditCompProps(null)
  }

  handleEditModalCompleted = event => {
    const { initialLocalComp } = this.state
    const { getLocalCompToEditFromLocation } = this.props
    const { source, salesComp } = event.detail

    if (source === EDIT_COMP_SOURCE_LOCATION) {
      const initialLocalComp = getLocalCompToEditFromLocation()
      this.saveEditedComp(salesComp, initialLocalComp)
      this.navigateAwayFromEditModal()
    } else if (source === EDIT_COMP_SOURCE_UPDATE_FAILED) {
      this.saveEditedComp(salesComp, initialLocalComp)
      this.hideEditModal()
    }
  }

  saveEditedComp = (updatedSalesComp, initialLocalComp) => {
    const { form } = this.props

    if (!initialLocalComp) {
      return
    }

    const updatedLocalCapRateComp = mapCompPlexSalesCompToCapRateComp(updatedSalesComp, initialLocalComp)

    const capRateComps = get(form, `values.capRateComps`, [])
    const compToEditIndex = capRateComps.findIndex(selectedComp => areSameComps(updatedLocalCapRateComp, selectedComp))

    if (compToEditIndex !== -1) {
      form.change(`capRateComps.${compToEditIndex}`, updatedLocalCapRateComp)
    }

    if (updatedSalesComp.deletedAt) {
      return
    }

    this.props.saveReport(this.props.formPath)
  }

  handleEditModalSelectToggled = event => {
    const { getLocalCompToEditFromLocation, removeComp } = this.props
    const { source, salesComp } = event.detail

    if (source !== EDIT_COMP_SOURCE_LOCATION) {
      return
    }

    const initialLocalComp = getLocalCompToEditFromLocation()
    const localCapRateComp = mapCompPlexSalesCompToCapRateComp(salesComp, initialLocalComp)
    removeComp(localCapRateComp)
    this.navigateAwayFromEditModal()
  }

  handleEditModalUpdated = event => {
    const { getLocalCompToEditFromLocation } = this.props
    const { source, salesComp } = event.detail

    if (source !== EDIT_COMP_SOURCE_LOCATION) {
      return
    }

    const initialLocalSalesComp = getLocalCompToEditFromLocation()
    this.saveEditedComp(salesComp, initialLocalSalesComp)
  }

  handleEditModalUpdateFailed = event => {
    const { getLocalCompToEditFromLocation } = this.props
    const { source, salesComp } = event.detail
    if (source !== EDIT_COMP_SOURCE_LOCATION) {
      return
    }
    const initialLocalSalesComp = getLocalCompToEditFromLocation()
    this.navigateAwayFromEditModal()

    this.showEditModal(
      {
        source: EDIT_COMP_SOURCE_UPDATE_FAILED,
        salesComp,
        creating: true,
      },
      initialLocalSalesComp
    )
  }

  render() {
    return null
  }
}

const mapStateToProps = state => {
  const { formPath } = get(state, 'shared.location.form', {})
  return {
    formPath,
  }
}

export default compose(withRouter, connect(mapStateToProps, { saveReport }))(EditSalesComp)
