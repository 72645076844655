import { filter, find, sumBy, some, findIndex } from 'lodash'

import { DEFAULT_ADJUSTMENT_ROWS } from './salesCompAdjustmentConstants'

export const getAdjustment = (adjustments, name) => {
  return find(adjustments, adjustment => adjustment?.name === name)
}

export const getAdjustmentValue = (adjustments, name) => {
  const adjustment = getAdjustment(adjustments, name)
  return adjustment && adjustment?.value ? adjustment?.value : 0
}

export const getAdjustmentGroup = (adjustments, groupName) => {
  return filter(adjustments, adjustment => adjustment?.groupName === groupName)
}

export const getTotalUtilityAdjustments = adjustments => {
  const allUtilityAdjustments = getAdjustmentGroup(adjustments, 'utility')
  return sumBy(allUtilityAdjustments, 'value')
}

export const getCustomRowNames = (compAdjustments, groupName) => {
  if (!compAdjustments || compAdjustments?.length === 0) {
    return []
  }
  const customRowNames = new Set()
  for (const [, compAdjustment] of compAdjustments.entries()) {
    const { adjustments } = compAdjustment
    adjustments.forEach(adjustment => {
      if (adjustment.custom === true && adjustment.groupName === groupName) {
        customRowNames.add(adjustment.name)
      }
    })
  }
  return Array.from(customRowNames)
}

export const getAllCustomAdjustments = compAdjustments => {
  if (!compAdjustments || compAdjustments?.length === 0) {
    return []
  }
  const customAdjustments = new Map()
  for (const [, compAdjustment] of compAdjustments.entries()) {
    const { adjustments } = compAdjustment
    adjustments.forEach(adjustment => {
      if (adjustment.custom === true) {
        customAdjustments.set(adjustment.name, { ...adjustment, value: 0 })
      }
    })
  }
  return Array.from(customAdjustments, ([, value]) => value)
}

export const hasAdjustments = (compAdjustments, name, group) => {
  if (!compAdjustments || compAdjustments.length === 0) {
    return false
  }
  const adjustments = compAdjustments.reduce((collection, compAdjustment) => {
    const adjustmentValue = getAdjustmentValue(getAdjustmentGroup(compAdjustment.adjustments, group), name)
    collection.push(adjustmentValue)
    return collection
  }, [])
  return some(adjustments, value => !!value)
}

export const containsAdjustmentName = (compAdjustments, name) => {
  if (!compAdjustments || compAdjustments.length === 0) {
    return false
  }
  return !!find(compAdjustments[0].adjustments, adjustment => adjustment.name === name)
}

export const getMissingCompAdjustments = (selectedComps, compAdjustments) => {
  const defaultAdjustments = DEFAULT_ADJUSTMENT_ROWS.map(row => ({
    name: row.name || '',
    value: 0,
    groupName: row.groupName,
    custom: false,
  }))

  const customAdjustments = getAllCustomAdjustments(compAdjustments)

  const allPotentialAdjustments = [...defaultAdjustments, ...customAdjustments]

  const allCompAdjustments = []
  for (const [, comp] of selectedComps.entries()) {
    const compAdjustment = compAdjustments.find(adj => adj.compId.toString() === comp._id.toString())
    if (compAdjustment) {
      // check adjustments compared to all adjustments
      const existingAdjustments = compAdjustment.adjustments
      if (existingAdjustments.length !== allPotentialAdjustments.length) {
        allPotentialAdjustments.forEach((potentialAdjustment, index) => {
          const foundAdjustment = find(
            existingAdjustments,
            existingAdjustment => existingAdjustment.name === potentialAdjustment.name
          )
          if (!foundAdjustment) {
            existingAdjustments.splice(index, 0, potentialAdjustment)
          }
        })
      }
      allCompAdjustments.push({
        compId: compAdjustment.compId,
        adjustments: existingAdjustments,
      })
    } else {
      allCompAdjustments.push({
        compId: comp._id,
        adjustments: allPotentialAdjustments,
      })
    }
  }
  return allCompAdjustments
}

export const getMissingCustomAdjustmentDiscussions = (otherAdjustmentDiscussions, compAdjustments) => {
  const customAdjustments = getAllCustomAdjustments(compAdjustments)

  const allOtherAdjustmentDiscussions = []
  customAdjustments.forEach(adjustment => {
    const { name } = adjustment
    const index = findIndex(otherAdjustmentDiscussions, discussion => discussion?.name === name)
    if (index === -1) {
      allOtherAdjustmentDiscussions.push({
        name,
        discussion: '',
      })
    } else {
      allOtherAdjustmentDiscussions.push(otherAdjustmentDiscussions[index])
    }
  })
  return allOtherAdjustmentDiscussions
}
