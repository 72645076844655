import { invert } from 'lodash'

import { SALES_COMPS_SORT_VALUES } from '../constants'
import { STATE_NAMES } from '../../../../../../../shared/constants/states'
import { sortCompsArray as sortByDateDesc, sortByScoreAsc } from '../../../../../../../shared/helpers/salesComps'

export function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((comp1, comp2) => {
    const order = cmp(comp1[0], comp2[0])
    if (order !== 0) {
      return order
    }
    return comp1[1] - comp2[1]
  })
  return stabilizedThis.map(el => el[0])
}

function desc(comp1, comp2, orderBy) {
  if (comp1[orderBy] < comp2[orderBy]) {
    return -1
  }
  if (comp1[orderBy] > comp2[orderBy]) {
    return 1
  }
  return 0
}

export function getSorting(order, orderBy) {
  return order === 'desc'
    ? (comp1, comp2) => desc(comp1, comp2, orderBy)
    : (comp1, comp2) => -desc(comp1, comp2, orderBy)
}

export const areSameComps = (compA, compB) => {
  if ((compA._id || compB._id) && compA._id === compB._id) {
    return true
  }
  if (compA.salesTransactionId || compB.salesTransactionId) {
    return compA.salesTransactionId === compB.salesTransactionId
  }

  return compA.address === compB.address && compA.zip === compB.zip && compA.saleDate === compB.saleDate
}

export const getSelectedSalesComps = (selectedSalesComps, newSelectedSalesComps, areCompsEqual = areSameComps) => {
  const updatedSelectedSalesComps = [...selectedSalesComps]

  newSelectedSalesComps.forEach(newComp => {
    newComp.isRemoved = false
    newComp.isAdded = true

    const previouslySelectedComp = updatedSelectedSalesComps.find(selectedComp => areCompsEqual(selectedComp, newComp))

    if (!previouslySelectedComp) {
      updatedSelectedSalesComps.push(newComp)
    }
  })

  return updatedSelectedSalesComps
}

export const getRemovedSalesComps = (removedSalesComps, newSelectedSalesComps, areCompsEqual = areSameComps) => {
  return removedSalesComps.filter(salesComp => {
    return !newSelectedSalesComps.some(newSalesComp => areCompsEqual(newSalesComp, salesComp))
  })
}

export const getSaleCompFullAddress = ({ streetAddress, city, state, postalCode }) => {
  const stateAbbreviations = invert(STATE_NAMES)
  return `${streetAddress}${city ? ', ' : ''}${city || ''}${state ? ', ' : ''}${stateAbbreviations[state] || ''}${
    postalCode ? ', ' : ''
  }${postalCode || ''}`
}

export const getSortedComps = (comps, sortBy) => {
  if (sortBy === SALES_COMPS_SORT_VALUES.DATE_SOLD) {
    return sortByDateDesc(comps)
  } else if (sortBy === SALES_COMPS_SORT_VALUES.RELEVANCE) {
    return sortByScoreAsc(comps)
  }
  return comps
}
