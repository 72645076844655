import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { partition, map, groupBy } from 'lodash'
import { formatList } from 'shared/utils/textHelpers'
import { UnitTypesLabel } from 'shared/helpers/commercialUses'

import NarrativeComponent from '../../../../../../shared/components/NarrativeComponent'
import { gtLocked, GeneratedText } from '../../../../../../../../shared/utils/narrative/GeneratedText'
import { DebouncedFormValuesSpy } from '../../../../../../shared/components/helpers'
import { simplePluralize as pluralize, pluralizeBeVerb } from '../../../../../../../../shared/utils/textGeneration'

const enumerate = units => units.map((unit, index) => ({ ...unit, number: index + 1 }))

const CurrentIncomeDiscussion = ({ form }) => {
  const getDiscussionData = useCallback(units => {
    const unitsLength = units.length
    const [vacantUnits, occupiedUnits] = partition(enumerate(units), 'isVacant')

    const occupiedUnitsList = formatList(map(occupiedUnits, 'number'))
    const vacantUnitsList = formatList(map(vacantUnits, 'number'))

    const unitsByType = groupBy(units, 'use')
    const types = Object.keys(unitsByType)

    const mappedUnitTypes = types.map(type => {
      const formattedType = UnitTypesLabel[type] || type
      const numberOfUnitsByType = unitsByType[type].length
      const unitsWord = pluralize('unit', numberOfUnitsByType)
      return `${numberOfUnitsByType} ${formattedType} ${unitsWord}`
    })
    const unitsByTypeSentence = formatList(mappedUnitTypes)
    return {
      units,
      unitsLength,
      unitsByTypeSentence,
      occupiedUnitsList,
      vacantUnitsList,
      types: {
        commercial: 'commercial',
        occupied: 'occupied',
        vacant: 'vacant',
      },
    }
  }, [])

  const generateIncomeDiscussion = useCallback(({ units }) => {
    const gt = new GeneratedText()

    const [vacantUnits, occupiedUnits] = partition(enumerate(units), 'isVacant')
    const vacantUnitsWord = pluralize('unit', vacantUnits.length)

    if (!occupiedUnits.length) {
      gt.t`The subject currently contains no occupied commercial units. `
      gt.t`We will forecast market rent and market lease terms for the vacant ${vacantUnitsWord} `
      gt.t`based on the comparables and our research of the subject's market.`

      return gt.getParts()
    }

    const unitsByTypeSentence = gtLocked('unitsByTypeSentence')

    gt.t`The subject currently contains ${unitsByTypeSentence}. `

    const leaseWord = pluralize('lease', occupiedUnits.length)
    const leaseVerb = pluralizeBeVerb(occupiedUnits.length)

    if (vacantUnits.length) {
      const occupiedUnitsWord = pluralize('Unit', occupiedUnits.length)
      const occupiedUnitsList = gtLocked('occupiedUnitsList')
      const occupiedUnitsVerb = pluralizeBeVerb(occupiedUnits.length)
      const occupiedType = gtLocked('types.occupied')
      gt.t`${occupiedUnitsWord} ${occupiedUnitsList} ${occupiedUnitsVerb} ${occupiedType}, `

      const vacantUnitsWord = pluralize('unit', vacantUnits.length)
      const vacantUnitsList = gtLocked('vacantUnitsList')
      const vacantUnitsVerb = pluralizeBeVerb(vacantUnits.length)
      const vacantType = gtLocked('types.vacant')
      gt.t`while ${vacantUnitsWord} ${vacantUnitsList} ${vacantUnitsVerb} currently ${vacantType}. `

      gt.t`The occupied unit ${leaseWord} ${leaseVerb} summarized below.`
    } else {
      gt.t`The ${leaseWord} ${leaseVerb} summarized below.`
    }

    return gt.getParts()
  }, [])

  return (
    <DebouncedFormValuesSpy
      form={form}
      render={updatedForm => {
        return (
          <NarrativeComponent
            name="currentCommercialIncomeDiscussion"
            title="Current Commercial Income Discussion"
            generatedText={generateIncomeDiscussion}
            data={getDiscussionData(updatedForm.values.units)}
            tooltipText="The following generated text will appear in the Income Approach section of your report."
            regenerateOnChange={['occupiedUnitsList', 'vacantUnitsList']}
          />
        )
      }}
    />
  )
}

CurrentIncomeDiscussion.propTypes = {
  form: PropTypes.object.isRequired,
}

export default React.memo(CurrentIncomeDiscussion)
