import React, { createRef } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { compose } from 'recompose'

import { findIndex, isEmpty, get } from 'lodash'
import { Grid, Paper, Typography, withStyles, TableCell } from '@material-ui/core'
import ObjectID from 'bson-objectid'

import { getRentPsfLabel } from 'shared/helpers/commercialIncome'
import { getStateAbbreviation } from 'shared/helpers/property'

import { CompPlexCommercialLeaseEvents, CompPlexEvents } from 'client-shared/constants/compPlex'

import { getAuthorizationHeader } from 'core/api'

import Badge from 'report/forms/sales/SalesCompsSearch/Badge'

import { VALUE_CONCLUSION_TYPES } from 'shared/constants/acas'

import { withMicrofrontend } from '../../../../../shared/hooks/useMicrofrontend'
import { RentTypesUnitsOfMeasure } from '../../../../../../../shared/constants/report/incomeApproach/'
import { shortDateFormat } from '../../../../../shared/utils/dateHelper'
import { handleOtherUseLabel } from '../../../../../../../shared/utils/formatters/textFormatters'
import { formatCurrencyFloat, formatInt } from '../../../../../shared/utils/numberFormatters'
import { getRentPerSF } from '../../../../../../../shared/calculations/commercial'
import { UNIT_USES_MAP } from '../../../../constants'
import { getSourceOfInformation } from '../../../../../shared/components/SourceOfInformation/SourceOfInformation'
import { getRemovedCompActions } from '../../../../utils/columnHelpers'
import { GeneratedComment } from '../../../../../shared/components'
import CompsImportForm from '../../../../../shared/components/CompsImportForm'
import { getDisplayAddress } from '../../../../../shared/utils/propertyHelper'
import RemovedCompsTable from '../../../../components/RemovedCompsTable'

import RentCompGroups from './RentCompGroups'
import { UNSORTED_GROUP_ID, DEFAULT_SORT_TYPE, REMOVED_COMMERCIAL_COMPS_TABLE_COLUMNS, Labels } from './constants'

import styles from './styles'
import FilterComputedPanel from './FilterComputedPanel'
import { transformCompPlexCompToWebapp } from './transformers'

class CommercialRentComps extends React.Component {
  static propTypes = {
    valueConclusionType: PropTypes.string.isRequired,
    commercialUnitCount: PropTypes.number.isRequired,
    importCommercialRentComps: PropTypes.func.isRequired,
    isImportingRentComps: PropTypes.bool.isRequired,
    importedRentComps: PropTypes.array,
  }

  state = {
    filters: {
      sortType: DEFAULT_SORT_TYPE,
      leaseTerms: [],
      locations: [],
      streetTypes: [],
      rentType: RentTypesUnitsOfMeasure.ANNUAL,
      use: [],
    },
    editModalComp: null,
    editModalToggle: false,
  }

  constructor() {
    super()
    this.compPlexRef = createRef()
  }

  get rentPsfLabel() {
    const { rentRollRentBasis } = this.props.form.values
    return getRentPsfLabel(rentRollRentBasis, Labels.RENT_PER_SF_PER_MONTH, Labels.RENT_PER_SF)
  }

  get removedCompsTableColumns() {
    return REMOVED_COMMERCIAL_COMPS_TABLE_COLUMNS.map(column => {
      if (column.name === 'rentPsf') {
        return { ...column, title: this.rentPsfLabel }
      }
      return column
    })
  }

  showEditModal = lease => {
    this.setState({ editModalComp: lease, editModalToggle: true })
  }

  hideEditModal = () => {
    this.setState({ editModalComp: null, editModalToggle: false })
  }

  componentDidUpdate(prevProps) {
    const { importedRentComps } = this.props

    const shouldAddImportedComps = importedRentComps !== prevProps.importedRentComps && !isEmpty(importedRentComps)

    if (shouldAddImportedComps) {
      this.addImportedRentComps()
    }
  }

  componentDidMount() {
    const { compPlexLoaded, errorNotification } = this.props
    if (!compPlexLoaded) {
      errorNotification('Failed to load rent comps service. Some features will not be available.')
    }

    if (this.compPlexRef.current) {
      this.compPlexRef.current.addEventListener(CompPlexCommercialLeaseEvents.EDIT_COMP_SAVED, this.handleAddComp)
      this.compPlexRef.current.addEventListener(CompPlexCommercialLeaseEvents.FOUND_COMP_ADDED, this.handleAddComp)
      this.compPlexRef.current.addEventListener(CompPlexCommercialLeaseEvents.EDIT_COMP_UPDATED, this.handleUpdateComp)
      this.compPlexRef.current.addEventListener(CompPlexCommercialLeaseEvents.EDIT_COMP_CLOSED, this.hideEditModal)
      this.compPlexRef.current.addEventListener(CompPlexCommercialLeaseEvents.MAP_COMP_ADDED, this.handleAddComp)
      this.compPlexRef.current.addEventListener(
        CompPlexCommercialLeaseEvents.MAP_COMP_REMOVED,
        this.handleMapCompRemoved
      )
      this.compPlexRef.current.addEventListener(CompPlexEvents.ERROR, this.handleError)
      this.compPlexRef.current.addEventListener(
        CompPlexCommercialLeaseEvents.MAP_EDIT_MODAL_OPENED,
        this.handleMapEditModalOpen
      )
      this.compPlexRef.current.addEventListener(
        CompPlexCommercialLeaseEvents.MAP_EDIT_MODAL_CLOSED,
        this.handleMapEditModalClosed
      )
    }
  }

  componentWillUnmount() {
    if (this.compPlexRef.current) {
      this.compPlexRef.current.removeEventListener(CompPlexCommercialLeaseEvents.EDIT_COMP_SAVED, this.handleAddComp)
      this.compPlexRef.current.removeEventListener(CompPlexCommercialLeaseEvents.FOUND_COMP_ADDED, this.handleAddComp)
      this.compPlexRef.current.removeEventListener(
        CompPlexCommercialLeaseEvents.EDIT_COMP_UPDATED,
        this.handleUpdateComp
      )
      this.compPlexRef.current.removeEventListener(CompPlexCommercialLeaseEvents.EDIT_COMP_CLOSED, this.hideEditModal)
      this.compPlexRef.current.removeEventListener(CompPlexCommercialLeaseEvents.MAP_COMP_ADDED, this.handleAddComp)
      this.compPlexRef.current.removeEventListener(
        CompPlexCommercialLeaseEvents.MAP_COMP_REMOVED,
        this.handleMapCompRemoved
      )
      this.compPlexRef.current.removeEventListener(CompPlexEvents.ERROR, this.handleError)
      this.compPlexRef.current.removeEventListener(
        CompPlexCommercialLeaseEvents.MAP_EDIT_MODAL_OPENED,
        this.handleMapEditModalOpen
      )
      this.compPlexRef.current.removeEventListener(
        CompPlexCommercialLeaseEvents.MAP_EDIT_MODAL_CLOSED,
        this.handleMapEditModalClosed
      )
    }
  }

  handleMapEditModalOpen = event => {
    const {
      detail: { commercialRentComp },
    } = event
    const webappComp = transformCompPlexCompToWebapp(commercialRentComp)
    this.showEditModal(webappComp)
  }

  handleMapEditModalClosed = () => {
    this.hideEditModal()
  }

  handleAddComp = event => {
    const {
      detail: { commercialRentComp },
    } = event
    const webappComp = transformCompPlexCompToWebapp(commercialRentComp)
    this.onAddComp(webappComp)
  }

  handleUpdateComp = event => {
    const {
      detail: { commercialRentComp },
    } = event
    const webappComp = transformCompPlexCompToWebapp(commercialRentComp)
    this.onUpdateCompPlexComp(webappComp)
  }

  handleMapCompRemoved = event => {
    const { leaseId } = event.detail
    this.onRemoveComp({ leaseId })
  }

  handleError = event => {
    const { error } = event.detail
    this.props.errorNotification(error.message || error)
  }

  addImportedRentComps = () => {
    const { importedRentComps, form } = this.props
    const { selectedRentComps = [] } = form.values
    const newSelectedRentComps = [...selectedRentComps, ...importedRentComps]
    form.change('selectedRentComps', newSelectedRentComps)
  }

  onAddComp = comp => {
    const { form } = this.props
    const { selectedRentComps = [], removedCommercialRentComps = [] } = form.values

    const { address, city, state, zip, leaseId, leaseVersionNumber } = comp
    const stateAbbreviation = getStateAbbreviation(state)
    const fullAddressWithZip = getDisplayAddress({ address, city, state: stateAbbreviation, zip })

    const _id = ObjectID().toJSON()
    const newComp = {
      ...comp,
      _id,
      commercialCompGroupId: UNSORTED_GROUP_ID,
      leaseId,
      leaseVersionNumber,
      fullAddressWithZip,
    }

    const PROPERTY_WIZARD_LOCATION_TYPE = 'object'
    if (typeof newComp.location === PROPERTY_WIZARD_LOCATION_TYPE) {
      delete newComp.location
    }
    const newSelectedRentComps = [...selectedRentComps, newComp]

    if (!removedCommercialRentComps.includes(comp)) {
      const updatedRemovedCommercialRentComps = removedCommercialRentComps.filter(unit => unit._id !== comp._id)
      form.batch(() => {
        form.change('selectedRentComps', newSelectedRentComps)
        form.change('removedCommercialRentComps', updatedRemovedCommercialRentComps)
      })
    } else {
      form.change('selectedRentComps', newSelectedRentComps)
    }

    this.closeSearchModal()
  }

  onUpdateCompPlexComp = comp => {
    const { form } = this.props
    const { editModalComp } = this.state
    const { selectedRentComps } = form.values

    const { address, city, state, zip } = comp
    const stateAbbreviation = getStateAbbreviation(state)
    const fullAddressWithZip = getDisplayAddress({ address, city, state: stateAbbreviation, zip })

    const updateCompIndex = findIndex(selectedRentComps, ['leaseId', editModalComp.leaseId])
    const compToUpdate = selectedRentComps[updateCompIndex]
    const updatedComps = [...selectedRentComps]
    const compId = ObjectID().toJSON()
    updatedComps[updateCompIndex] = {
      ...comp,
      _id: compToUpdate._id || compId,
      leaseId: comp.leaseId,
      leaseVersionNumber: comp.leaseVersionNumber,
      fullAddressWithZip,
      commercialCompGroupId: compToUpdate.commercialCompGroupId,
    }
    form.change('selectedRentComps', updatedComps)
  }

  onUpdateAllComps = updatedComps => {
    this.props.form.change('selectedRentComps', [...updatedComps])
  }

  onRemoveComp = comp => {
    const { form } = this.props
    const { selectedRentComps, removedCommercialRentComps } = form.values

    const updatedComps = selectedRentComps.filter(selectedComp => selectedComp.leaseId !== comp.leaseId)

    if (isEmpty(removedCommercialRentComps.filter(unit => unit.leaseId === comp.leaseId))) {
      const compToRemove = selectedRentComps.find(unit => unit.leaseId === comp.leaseId)
      form.batch(() => {
        form.change('selectedRentComps', updatedComps)
        form.mutators.push('removedCommercialRentComps', compToRemove)
      })
    } else {
      form.change('selectedRentComps', updatedComps)
    }
  }

  getComparableRentalsIntroduction = () => {
    const { valueConclusionType, commercialUnitCount } = this.props
    const isAsIsType = valueConclusionType === VALUE_CONCLUSION_TYPES.AS_IS

    const rentsWord = commercialUnitCount === 1 ? 'rent' : 'rents'

    const asIsIntroduction =
      `In order to determine the reasonableness of the contract ${rentsWord}, we have` +
      ` conducted a survey of the recently signed leases for commercial units in the subject’s neighborhood.`
    const otherIntroduction =
      `In order to forecast the market ${rentsWord}, we have` +
      ` conducted a survey of the recently signed leases for commercial units in the subject’s neighborhood.`

    return isAsIsType ? asIsIntroduction : otherIntroduction
  }

  restoreComp = compIndex => {
    const { form } = this.props
    const { removedCommercialRentComps, selectedRentComps = [] } = form.values
    const removedComp = removedCommercialRentComps[compIndex]
    const newSelectedRentComps = [...selectedRentComps, removedComp]
    const newRemovedCommercialRentComps = removedCommercialRentComps.filter((value, index) => index !== compIndex)
    form.batch(() => {
      form.change('removedCommercialRentComps', newRemovedCommercialRentComps)
      form.change('selectedRentComps', newSelectedRentComps)
    })
  }

  deleteRemovedComp = compIndex => {
    const { form } = this.props
    const { removedCommercialRentComps } = form.values
    const newRemovedCommercialRentComps = removedCommercialRentComps.filter((value, index) => index !== compIndex)

    form.change('removedCommercialRentComps', newRemovedCommercialRentComps)
  }

  removedCompsTableRowCell = column => {
    const {
      comp,
      column: { name, defaultValue },
    } = column
    const { rentRollRentBasis } = this.props.form.values
    const { classes } = this.props
    let cellContent

    switch (name) {
      case 'status':
        cellContent = <Badge className={classes.statusBadge} type={get(comp, name)} />
        break
      case 'use':
        cellContent = handleOtherUseLabel(UNIT_USES_MAP, comp.use === 'other' ? comp.otherUse : comp.use)
        break
      case 'squareFeet':
        cellContent = formatInt(get(comp, name, defaultValue))
        break
      case 'dateSigned':
        cellContent = shortDateFormat(get(comp, name, defaultValue))
        break
      case 'rentPsf':
        cellContent = formatCurrencyFloat(getRentPerSF(comp, rentRollRentBasis))
        break
      case 'sourceOfInformation':
        cellContent = getSourceOfInformation(comp)
        break
      case 'actions':
        cellContent = getRemovedCompActions(column, classes, this.restoreComp, this.deleteRemovedComp)
        break
      default:
        cellContent = get(comp, name, defaultValue)
    }

    return (
      <TableCell data-qa={`removed-comps-table-${name}-cell`} className={classes.removedCompsTableCell}>
        {cellContent}
      </TableCell>
    )
  }

  getEditModalProps = () => {
    const { editModalComp, editModalToggle } = this.state
    return {
      showEditModal: editModalToggle,
      leaseId: editModalComp?.leaseId || null,
      leaseVersionNumber: editModalComp?.leaseVersionNumber || null,
    }
  }

  render() {
    const {
      classes,
      form,
      importCommercialRentComps,
      isImportingRentComps,
      authenticatedUser,
      subjectCoordinates,
      reportId,
      reportNumber,
      compGroups,
    } = this.props

    const { selectedRentComps } = form.values
    const editModalProps = this.getEditModalProps()
    const subjectProps = {
      coordinates: subjectCoordinates,
    }
    const selectedRentCompIds = selectedRentComps.map(comp => ({ id: comp.leaseId }))
    const reportData = { jobNumber: reportNumber, reportId }

    return (
      <Grid container spacing={8}>
        <Field name="selectedRentComps">{() => null}</Field>
        <Grid item sm={6} className={classes.root}>
          <Grid item xs={12} sm={12}>
            <Typography variant="caption">Computed Values Panel</Typography>
          </Grid>
          <Paper className={classes.computedPanelContainer}>
            <FilterComputedPanel selectedRentComps={selectedRentComps} />
          </Paper>
        </Grid>
        <Grid item sm={12} className={classes.root}>
          <Paper className={classes.newUnitModal}>
            <div className={classes.buttonWrapper}>
              <CompsImportForm
                tooltipText="Use this to copy over commercial comparables saved to another report."
                import={importCommercialRentComps}
                isLoading={isImportingRentComps}
              />
            </div>
          </Paper>
          <comp-plex-commercial-lease
            ref={this.compPlexRef}
            auth-user={JSON.stringify(authenticatedUser)}
            webapp-auth-header={getAuthorizationHeader().Authorization}
            webapp-api-url={global.env.apiUrl}
            edit-lease-props={JSON.stringify(editModalProps)}
            subject={JSON.stringify(subjectProps)}
            selected-comps={JSON.stringify(selectedRentCompIds)}
            meta-data={JSON.stringify(reportData)}
          />
        </Grid>
        <Grid item sm={12}>
          <Paper className={classes.paper}>
            <GeneratedComment
              label="Comparable Rentals Introduction"
              dataPath="comparableRentalsIntroduction"
              title="Generated commentary"
              isDynamicContent
              getGeneratedText={this.getComparableRentalsIntroduction}
            />
          </Paper>
        </Grid>
        <Grid item sm={12}>
          <RentCompGroups
            onUpdateAllComps={this.onUpdateAllComps}
            onRemoveComp={this.onRemoveComp}
            compGroups={compGroups}
            selectedRentComps={selectedRentComps}
            rentPsfLabel={this.rentPsfLabel}
            form={form}
            showEditModal={this.showEditModal}
            hideEditModal={this.hideEditModal}
          />
        </Grid>
        <Grid item sm={12}>
          <RemovedCompsTable
            title="Removed Comp"
            form={form}
            cellComponent={this.removedCompsTableRowCell}
            columns={this.removedCompsTableColumns}
            fieldName="removedCommercialRentComps"
          />
        </Grid>
      </Grid>
    )
  }
}

export default compose(withStyles(styles), withMicrofrontend('compplex', 'compPlexLoaded'))(CommercialRentComps)
