import { upperFirst } from 'lodash'

import { TIME_PERIODS } from '../../../../../../../shared/constants/report/incomeApproach'

import { numberToWords, simplePluralize as pluralize } from '../../../../../../../shared/utils/textGeneration'

import { arrayToKeyValuePairs } from '../../../../../shared/utils/ui/checkboxHelper'
import { RentRollOutdoorSpacesList, UNIT_LAYOUTS } from '../../../../constants'

export const Labels = {
  NUMBER_OF_RESIDENTIAL_UNITS: 'Number of Residential Units',
  DO_YOU_KNOW_PER_UNIT_SQUARE_FOOTAGE: 'Do you know per unit square footage?',
  SUMMARIZE_CURRENT_RENT_ROLL: 'Summarize current rent roll',
  DEVELOPERS_FORECAST: "Developer's Forecast",
  OPTIONAL_COLUMNS: 'Optional Columns',
  BATHROOMS: 'Bathrooms',
  OUTDOOR_SPACE: 'Outdoor Space',
  UNIT_LAYOUT: 'Unit Type',
  RENT_TYPE_OPTIONS: 'Rent Type Options',
  OUTDOOR_SPACE_OPTIONS: 'Outdoor Space Options',
  UNIT_LAYOUT_OPTIONS: 'Unit Layout Options',
  YES: 'Yes',
  NO: 'No',
  ROOMS: 'Rooms',
  UNIT_NUMBER: 'Unit Number',
  BEDROOMS: 'Bedrooms',
  SQUARE_FOOTAGE: 'Square Footage',
  RENT_TYPE: 'Rent Type',
  MONTHLY_RENT: 'Monthly Rent',
  RENT_PER_ROOM: 'Rent/Room',
  RENT_PER_SF: 'Rent PSF',
  RENT_FORECAST: 'Rent Forecast',
  VACANT: 'Vacant',
  ACTION: 'Action',
  LEASE_STATUS: 'Lease Status',
  PER_ROOM_ANALYSIS: 'Include Per Room Analysis in Report',
  INCLUDE_PSF_IN_MARKET_RATE_COMPARISON_DISCUSSION_AND_TABLE_IN_REPORT:
    'Include Per SF in the Market Rate Comparison Discussion and table in Report',
}

export const TrueOrFalseValues = {
  YES: 'Yes',
  NO: 'No',
}

export const LEASE_STATUS_OPTIONS = ['Occupied', 'Vacant', 'Employee']

export const CSVFileColumns = {
  NUMBER: 'Unit Number',
  BEDROOMS: '# Bedrooms',
  ROOMS: '# Rooms',
  BATHROOMS: '# Bathrooms',
  SQUARE_FOOTAGE: 'Square Footage',
  OUTDOOR_SPACE: 'Outdoor Space',
  UNIT_LAYOUT: 'Unit Type',
  RENT_TYPE: 'Rent Type',
  RENT: 'Monthly Rent ($)',
  RENT_FORECAST: 'Developer Monthly Rent ($)',
  VACANT: 'Vacant?',
  INSPECTED: 'Inspected?',
}

export const REQUIRED_COLUMNS = [
  CSVFileColumns.NUMBER,
  CSVFileColumns.BEDROOMS,
  CSVFileColumns.RENT_TYPE,
  CSVFileColumns.RENT,
  // CSVFileColumns.VACANT,
]

export const CSV_PARSER_CONFIG = {
  header: true,
  skipEmptyLines: true,
}

export const BATHROOMS_ERROR_MESSAGE = 'Must be a whole or half number'

export const BEDROOM_TYPES = [
  { label: 'Studio', value: 0 },
  { label: '1 Bedroom', value: 1 },
  { label: '2 Bedroom', value: 2 },
  { label: '3 Bedroom', value: 3 },
  { label: '4 Bedroom', value: 4 },
  { label: '5 Bedroom', value: 5 },
  { label: '6 Bedroom', value: 6 },
  { label: '7 Bedroom', value: 7 },
  { label: '8 Bedroom', value: 8 },
  { label: '9 Bedroom', value: 9 },
]

const getUnitLayoutLabel = unitLayout => unitLayout || ''

const getBedroomLabel = (bedrooms, forExport = false) => {
  if (bedrooms < 0) {
    return null
  }

  if (forExport) {
    return bedrooms === 0 ? 'Studio' : `${bedrooms} BR`
  }

  return bedrooms === 0 ? 'Studio' : `${bedrooms} Bedroom`
}

const getBathroomLabel = (bathrooms, forExport = false) => {
  if (forExport) {
    return `with ${upperFirst(numberToWords(bathrooms))} ${pluralize('Bathroom', bathrooms)}`
  }

  return `with ${bathrooms} ${pluralize('Bathroom', bathrooms)}`
}

const getOutdoorSpaceLabel = outdoorSpace => {
  if (!outdoorSpace || outdoorSpace === 'None') {
    return 'without Outdoor Space'
  }

  return `with ${outdoorSpace}`
}

export const SUBJECT_UNIT_GROUPING_TYPES = {
  bedroom: [{ path: 'bedrooms', label: getBedroomLabel }],
  bedroomAndUnitLayout: [
    { path: 'bedrooms', label: getBedroomLabel },
    { path: 'unitLayout', label: getUnitLayoutLabel },
  ],
  bedroomAndOutdoorSpace: [
    { path: 'bedrooms', label: getBedroomLabel },
    { path: 'outdoor', label: getOutdoorSpaceLabel },
  ],
  bedroomAndBathroom: [
    { path: 'bedrooms', label: getBedroomLabel },
    { path: 'bathrooms', label: getBathroomLabel },
  ],
}

export const SUBJECT_UNIT_GROUPING_LIST_OPTIONS = {
  bedroom: { value: 'bedroom', label: 'None' },
  bedroomAndUnitLayout: { value: 'bedroomAndUnitLayout', label: 'Unit Type' },
  bedroomAndOutdoorSpace: { value: 'bedroomAndOutdoorSpace', label: 'Outdoor Space' },
  bedroomAndBathroom: { value: 'bedroomAndBathroom', label: 'Bathroom Count' },
}

export const UNIT_MIX_GROUPING_TYPE_OPTIONS = {
  bedroom: BEDROOM_TYPES,
  bedroomAndOutdoorSpace: RentRollOutdoorSpacesList,
  bedroomAndBathroom: Array(10)
    .fill()
    .map((value, index) => ({ label: index, value: index })),
  bedroomAndUnitLayout: arrayToKeyValuePairs(UNIT_LAYOUTS),
}

export const PER_UNIT_SF_OPTIONS = [
  { value: true, label: Labels.YES },
  { value: false, label: Labels.NO },
]

export const CURRENT_RENT_ROLL_DISCUSSION_TOOLTIP =
  "The following generated text will appear in the Income Approach's Current Residential Rent Roll."

export const RENT_PSF_TIME_PERIODS_OPTIONS = [
  { label: 'Per Year', value: TIME_PERIODS.ANNUALLY },
  { label: 'Per Month', value: TIME_PERIODS.MONTHLY },
]

export const FEATURE_FLAG_AUTOMATED_RENT_ROLL = 'automated-rent-roll'
