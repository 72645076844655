import React from 'react'
import PropTypes from 'prop-types'
import { map } from 'lodash'
import { OnChange } from 'react-final-form-listeners'

import { COMMERCIAL_RENT_ROLL_PATH } from 'shared/constants/report/keysAndDataPaths'
import { parseToFloat } from 'report/forms/income/residential/ResidentialRentRoll/tools'
import { CHANGE_EVENT_INDEX } from 'client-shared/components/Table/constants'

import { getRents } from '../../../../../../../../../shared/calculations/commercial'
import { COMMERCIAL_UNIT_RENT_FIELD_NAMES } from '../../../../../../../../../shared/constants/report/incomeApproach/commercial'
import { isRentPsfMonthly } from '../../../../../../../../../shared/helpers/commercialIncome'
import { TableField as Table } from '../../../../../../../shared/components/_mui5/Table'
import { createTextRenderer, getFieldValue } from '../../../../../../../shared/components/_mui5/Table/helpers'
import { divide } from '../../../../../../../../../shared/utils/numberOperations'
import { LeaseStatusTypes } from '../../../../../../../../../shared/constants/report/incomeApproach/residential'
import { getNonVacantUnits } from '../../tools'

import { inPlaceColumns, projectedColumns, RENT_FIELD_NAME_TO_RENT_BASIS } from './constants'

export const DATA_PATH = COMMERCIAL_RENT_ROLL_PATH

const totalCellRenderer = createTextRenderer((row, col, prop, value) => value ?? `-`)

class RentRollTable extends React.PureComponent {
  static propTypes = {
    form: PropTypes.object.isRequired,
    isProjectedRentRoll: PropTypes.bool,
  }
  static defaultPropTypes = {
    isProjectedRentRoll: false,
  }

  state = {
    hiddenColumns: [],
  }

  tableRef = React.createRef()

  columns = []

  constructor(props) {
    super(props)
    const columns = props.isProjectedRentRoll ? projectedColumns : inPlaceColumns
    this.columns = columns
  }

  componentDidMount() {
    const { form } = this.props
    const rentBasis = getFieldValue(form, 'rentBasis')
    this.updateColumns(rentBasis)
  }

  beforeChange = (changes = []) => {
    if (!changes) {
      return
    }

    const { form } = this.props
    const tableUnits = getFieldValue(form, 'units')

    changes.forEach(unitChange => {
      const unitIndex = unitChange[CHANGE_EVENT_INDEX.row]
      const columnName = unitChange[CHANGE_EVENT_INDEX.column]
      let newValue = unitChange[CHANGE_EVENT_INDEX.newValue]

      if (!tableUnits[unitIndex]) {
        tableUnits.push({
          index: unitIndex,
          '#': unitIndex + 1,
        })
      }

      switch (columnName) {
        case 'leaseStatus':
          const isVacant = newValue === LeaseStatusTypes.VACANT
          const prevIsVacantValue = tableUnits[unitIndex].isVacant
          tableUnits[unitIndex]['isVacant'] = isVacant
          tableUnits[unitIndex]['leaseStatus'] = newValue

          if (prevIsVacantValue !== isVacant) {
            tableUnits[unitIndex]['tenantName'] = isVacant ? `Commercial Unit ${unitIndex + 1}` : ''
          }
          tableUnits[unitIndex]['annualRent'] = 0
          tableUnits[unitIndex]['monthlyRent'] = 0
          tableUnits[unitIndex]['annualRentPsf'] = 0
          tableUnits[unitIndex]['monthlyRentPsf'] = 0
          tableUnits[unitIndex]['leaseStartDate'] = null
          tableUnits[unitIndex]['leaseExpiryDate'] = null
          break

        case 'annualRent':
        case 'monthlyRent':
        case 'annualRentPsf':
        case 'monthlyRentPsf':
          const squareFeet = tableUnits[unitIndex]?.squareFeet
          newValue = parseToFloat(newValue) || 0
          const rentBasis = RENT_FIELD_NAME_TO_RENT_BASIS[columnName]
          const rentCalculationInfo = { rentType: rentBasis, baseRent: newValue, squareFeet }

          const { annualRent, annualRentPsf, monthlyRent, monthlyRentPsf } = getRents(rentCalculationInfo)

          tableUnits[unitIndex]['annualRent'] = annualRent
          tableUnits[unitIndex]['monthlyRent'] = monthlyRent
          tableUnits[unitIndex]['annualRentPsf'] = annualRentPsf
          tableUnits[unitIndex]['monthlyRentPsf'] = monthlyRentPsf
          break

        default:
          break
      }
      tableUnits[unitIndex][columnName] = newValue
    })

    form.change('units', [...tableUnits])
    return false
  }

  getInPlaceCellConfig = (row, col, prop) => {
    const { form } = this.props

    const data = getFieldValue(form, 'units')
    const rentBasis = getFieldValue(form, 'rentBasis')
    if (!data) {
      return {}
    }

    /*
     * Total Row
     */
    const isTotalRow = row > data.length - 1
    if (isTotalRow) {
      return this.getTotalCellConfig(row, col, prop)
    }

    /*
     * Unit Row
     */
    let cellConfig = {}
    const column = this.columns[col]
    const isVacant = data[row].isVacant

    if (column.readOnlyWhenVacant) {
      if (isVacant) {
        cellConfig = {
          readOnly: true,
        }
      } else {
        switch (column.name) {
          case 'monthlyRent':
          case 'annualRent':
          case 'annualRentPsf':
          case 'monthlyRentPsf':
            const basisColumnName = COMMERCIAL_UNIT_RENT_FIELD_NAMES[rentBasis]

            cellConfig = {
              readOnly: column.name !== basisColumnName,
              renderer: column.cellProps.renderer,
            }
            break
          default:
            cellConfig = {
              readOnly: false,
            }
        }
      }
    }

    return cellConfig
  }

  getProjectedRentRollCellConfig = (row, col, prop) => {
    const { form } = this.props

    const data = getFieldValue(form, 'units')
    if (!data) {
      return {}
    }

    /*
     * Total Row
     */
    const isTotalRow = row > data.length - 1
    if (isTotalRow) {
      return this.getTotalCellConfig(row, col, prop)
    }

    /*
     * Unit Row
     */
    let cellConfig = {}
    const column = projectedColumns[col]
    const isVacant = data[row].isVacant
    const isPsfColumn = ['annualRentPsf', 'monthlyRentPsf'].includes(column.name)

    cellConfig = {
      readOnly: !(isVacant && isPsfColumn),
    }

    return cellConfig
  }

  getTotalCellConfig = (row, col, prop) => {
    const column = this.columns[col]

    switch (column.name) {
      case 'squareFeet':
      case 'annualRent':
      case 'monthlyRent':
      case 'annualRentPsf':
      case 'monthlyRentPsf':
        return {
          readOnly: true,
          className: 'totalCell',
          renderer: column.cellProps?.renderer,
        }

      default:
        return {
          readOnly: true,
          className: 'totalCell',
          renderer: totalCellRenderer,
        }
    }
  }

  getTotalRowData = () => {
    const { form, isProjectedRentRoll } = this.props

    const units = getFieldValue(form, 'units') || []
    const unitsForRentSums = isProjectedRentRoll ? units : getNonVacantUnits(units)

    const totalSquareFeet = units.reduce((sum, unit) => sum + (unit.squareFeet || 0), 0)
    const totalOccupiedSquareFeet = unitsForRentSums.reduce((sum, unit) => sum + (unit.squareFeet || 0), 0)
    const totalAnnualRent = unitsForRentSums.reduce((sum, unit) => sum + (unit.annualRent || 0), 0)
    const totalMonthlyRent = unitsForRentSums.reduce((sum, unit) => sum + (unit.monthlyRent || 0), 0)

    return {
      '#': 'Total',
      squareFeet: totalSquareFeet,
      annualRent: totalAnnualRent,
      monthlyRent: totalMonthlyRent,
      annualRentPsf: divide(totalAnnualRent, totalOccupiedSquareFeet),
      monthlyRentPsf: divide(totalMonthlyRent, totalOccupiedSquareFeet),
    }
  }

  generateTotalRowCellUpdates = () => {
    const rowData = this.getTotalRowData()
    const updates = map(rowData, (value, key) => {
      return [-1, key, value]
    })

    return updates
  }

  updateColumns = rentBasis => {
    const annualRentPsfColumnIndex = this.columns.findIndex(({ name }) => name === 'annualRentPsf')
    const monthlyRentPsfColumnIndex = this.columns.findIndex(({ name }) => name === 'monthlyRentPsf')

    const hiddenColumn = isRentPsfMonthly(rentBasis) ? annualRentPsfColumnIndex : monthlyRentPsfColumnIndex

    this.setState({ hiddenColumns: [hiddenColumn] })
  }

  render() {
    const { hiddenColumns } = this.state
    const { form, isProjectedRentRoll } = this.props
    const additionalData = [this.getTotalRowData()]

    return (
      <>
        <OnChange name="rentBasis">{this.updateColumns}</OnChange>
        <Table
          initTable={ref => (this.tableRef = ref)}
          form={form}
          dataPath="units"
          additionalData={additionalData}
          rerenderOnChangeOf={['rentBasis']}
          columns={this.columns}
          hiddenColumns={{ columns: hiddenColumns }}
          beforeChange={this.beforeChange}
          cells={isProjectedRentRoll ? this.getProjectedRentRollCellConfig : this.getInPlaceCellConfig}
        />
      </>
    )
  }
}

export default RentRollTable
