import React from 'react'
import { get } from 'lodash'
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined'
import { ImageTooltip } from 'client-shared/components'
import { NO_IMAGE_AVAILABLE } from 'report/constants'
import { Grid } from '@mui/material'

const ColumnHeader = ({ comp }) => {
  const address = get(comp, 'address.streetAddress')
  const photoUrl = get(comp, 'propertyInformation.photo.cdnUrl', NO_IMAGE_AVAILABLE)
  return (
    <Grid container spacing={1}>
      <Grid item xs={9}>
        {address}
      </Grid>
      <Grid item xs={3}>
        <ImageTooltip
          placement="right"
          popperTemplate={
            <img style={{ height: '248px', width: '248px' }} src={photoUrl || NO_IMAGE_AVAILABLE} alt="Sales Comp" />
          }
        >
          <PhotoOutlinedIcon />
        </ImageTooltip>
      </Grid>
    </Grid>
  )
}

export default ColumnHeader
