import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import arrayMutators from 'final-form-arrays'
import { useFieldArray } from 'react-final-form-arrays'
import { Stack, Button, Paper, Typography, Tooltip } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { get, isNil, isNumber, partition, sum } from 'lodash'

import ObjectID from 'bson-objectid'

import BoweryDate from '@bowery-valuation/bowery-date'
import { FeatureToggle, getFeatureFlagValue } from '@bowery-valuation/feature-flagger-client'

import expenseHistoryDiscussion from 'shared/utils/textGeneration/incomeApproach/expenses/expenseHistory'

import {
  FEATURE_FLAG_AUTOMATED_EXPENSE_HISTORY,
  FEATURE_FLAG_SIMPLIFIED_EXPENSES_STRUCTURE,
} from 'shared/constants/featureFlags'
import { EXPENSE_PERIODS_WITHOUT_PROJECTED, EXPENSE_PERIOD_VALUES } from 'client-shared/utils/expenses/constants'

import { EXPENSE_DATA_SOURCES } from 'shared/constants/expenses/expenseDataSource'

import {
  UTILITIES_EXPENSES_MODE_OPTIONS,
  HIDDEN_HISTORICAL_EXPENSES,
  UTILITIES_EXPENSES_MODE,
  MONTHS,
} from 'shared/constants/expenses'

import { updateHistoricalExpensesByUtilitiesMode } from 'shared/utils/updateExpensesByUtilitiesMode'
import { EXPENSE_HISTORY_PATH } from 'shared/constants/report/keysAndDataPaths'
import { toggleCustomToStrictStructure, toggleStrictToCustomStructure } from 'shared/utils/expenses/mappers'

import AutocompleteField from 'client-shared/components/Fields/Autocomplete'
import { SwitchField } from 'client-shared/components/_mui5/Switch'
import wrapForm from 'report/forms/wrapForm'
import { arrayToKeyValuePairs } from 'client-shared/utils/ui/checkboxHelper'
import { Callout, NarrativeComponent, RadioButtonList } from 'client-shared/components'
import * as Socket from 'client-shared/utils/socket'
import { isProjectionPeriod } from 'shared/helpers/incomeApproach/expenses/helpers'

import { Input, Select } from '@ui/Field'
import Layout from '@ui/Layout'

import { EXPENSE_HISTORY_TYPE_TITLES } from 'shared/constants/expenses/expenseHistory'

import { EXPENSE_HISTORY_CATEGORY, PRO_FORMA_CATEGORY } from './constants'

import { flattenExpenseCategories, getZeroBasedMonth, isProjectionOrActualPeriod } from './helpers'
import StrictExpenseHistoryTable from './tables/StrictExpenseHistoryTable'
import AutomationContainer from './AutomationContainer'
import { columnDecorator, defaultDate, rowDecorator, setExpenseDate } from './decorators'
import { useTableUndo } from './useTableUndo'
import ExpenseHistoryTable from './tables/ExpenseHistoryTable'

const DATA_PATH = EXPENSE_HISTORY_PATH

const heading = 'Expense History'

const EXPENSE_DATA_SOURCE_OPTIONS = arrayToKeyValuePairs(EXPENSE_DATA_SOURCES)
const SUBJECT_AS_EXPENSE_OPTIONS = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
]

const EXPENSE_HISTORY_TOOLTIP =
  "The following generated text will appear in the Income Approach's Operating Expense Analysis of your report."
const EXPENSE_HISTORIES_LIMIT_WARNING = 'Only four years of historical or projected expenses will export.'

const validateYear = (year, values) => {
  const expensePeriod = get(values, 'expensePeriod')
  const currentYear = new BoweryDate().year
  switch (expensePeriod) {
    case EXPENSE_HISTORY_TYPE_TITLES.ACTUAL:
    case EXPENSE_HISTORY_TYPE_TITLES.ACTUAL_T_12:
    case EXPENSE_HISTORY_TYPE_TITLES.ANNUALIZED_HISTORICAL:
      if (year > currentYear) {
        return `Enter year prior to ${currentYear}`
      }
      return null
    case EXPENSE_HISTORY_TYPE_TITLES.PROJECTION:
      if (year < currentYear) {
        return `Enter year after ${currentYear - 1}`
      }
      return null
    default:
      return null
  }
}

const disableAdd = (expensePeriod, expenseMonth, expenseYear, form) => {
  const validatedYear = form.errors?.expenseYear
  return !expensePeriod || !(MONTHS.includes(expenseMonth) && expenseYear && isNil(validatedYear))
}

export const ExpenseHistoryContainer = ({ form, reportNumber }) => {
  const formValues = get(form.getState(), 'values', {})
  const { expenseMonth, expensePeriod, expenseYear, basisOfComparison, isStrictExpensesMode, expenseHistory } =
    formValues

  const isSimplifiedExpensesEnabled = getFeatureFlagValue(FEATURE_FLAG_SIMPLIFIED_EXPENSES_STRUCTURE)

  const [basis, setBasis] = useState(basisOfComparison || null)
  const { fields: expenseCategoriesFields } = useFieldArray('expenseCategories', {})

  const { UndoButton, resetUndo, makeUndoable } = useTableUndo(form)

  const onImportStatusUpdate = useCallback(
    ({ status, category }) => {
      switch (category) {
        case EXPENSE_HISTORY_CATEGORY:
          form.change('expenseHistoryAutomationMetadata.importStatus', status)
          break

        case PRO_FORMA_CATEGORY:
          form.change('proFormaAutomationMetadata.importStatus', status)
          break

        default:
          break
      }
    },
    [form]
  )

  useEffect(() => {
    form.change('basisOfComparison', basis)
  }, [basis, form])

  useEffect(() => {
    Socket.on(`import-status:expense-history:${reportNumber}`, onImportStatusUpdate)
    Socket.on(`import-status:pro-forma:${reportNumber}`, onImportStatusUpdate)

    return () => {
      Socket.off(`import-status:expense-history:${reportNumber}`, onImportStatusUpdate)
      Socket.off(`import-status:pro-forma:${reportNumber}`, onImportStatusUpdate)
    }
  }, [onImportStatusUpdate, reportNumber])

  const utilitiesExpensesMode = get(formValues, 'utilitiesExpensesMode')

  const onAddNewColumn = useCallback(() => {
    const formValues = form.getState().values
    const { expenseHistory, isStrictExpensesMode, expenseDate, expensePeriod, expenseYear, expenseMonth } = formValues

    const utilitiesExpensesMode = isStrictExpensesMode
      ? UTILITIES_EXPENSES_MODE.COMBINED_ALL
      : get(formValues, 'utilitiesExpensesMode')

    const column = {
      expenseDate,
      expensePeriod,
      expenseYear,
      expenseMonth: getZeroBasedMonth(expenseMonth),
      expenses: expenseCategoriesFields.value.map(({ id }) => ({ id })),
      hiddenExpenses: HIDDEN_HISTORICAL_EXPENSES[utilitiesExpensesMode].map(id => ({ id })),
      key: ObjectID().toString(),
    }

    const updatedExpenseHistory = [...expenseHistory, column]

    updatedExpenseHistory.sort((compA, compB) => {
      const boweryDate = new BoweryDate(compA.expenseDate)
      if (boweryDate.isDateAfter(compB.expenseDate)) {
        return 1
      } else if (boweryDate.isDateBefore(compB.expenseDate)) {
        return -1
      }
      return 0
    })

    form.batch(() => {
      form.change('expenseYear')
      form.change('expenseDate')
      form.change('expensePeriod')
      form.change('expenseHistory', updatedExpenseHistory)
    })
  }, [expenseCategoriesFields.value, form])

  const changeUtilitiesExpensesMode = useCallback(
    event => {
      const nextValue = event.target.value
      const prevValue = utilitiesExpensesMode

      // Initially there was an idea to wrap it with form.batch, but it turned out that in this case
      // calculations via decorators don't work as structure and values are updated together
      // and fields for updated categories just haven't been registered yet
      // So, order of 2 form changes is also important

      form.change('utilitiesExpensesMode', nextValue)
      const { expenseCategories: updatedExpenseCategories, expenseHistory: updatedExpenseHistory } =
        updateHistoricalExpensesByUtilitiesMode(prevValue, nextValue, expenseCategoriesFields.value, expenseHistory)
      form.change('expenseCategories', updatedExpenseCategories)
      form.change('expenseHistory', updatedExpenseHistory)
      resetUndo()
    },
    [form, expenseCategoriesFields, expenseHistory, resetUndo, utilitiesExpensesMode]
  )

  const changeExpensesMode = useCallback(
    event => {
      const newIsStrictExpensesMode = event.target.checked
      if (newIsStrictExpensesMode) {
        const { expenseCategories: updatedExpenseCategories, expenseHistory: updatedExpenseHistory } =
          toggleCustomToStrictStructure(
            { expenseCategories: expenseCategoriesFields.value, expenseHistory },
            utilitiesExpensesMode
          )

        form.change('isStrictExpensesMode', newIsStrictExpensesMode)
        form.change('expenseCategories', updatedExpenseCategories)
        form.change('expenseHistory', updatedExpenseHistory)
        resetUndo()
      } else {
        const {
          expenseHistory: { expenseHistory: updatedExpenseHistory, expenseCategories: updatedExpenseCategories },
          utilitiesExpensesMode: updatedUtilitiesExpensesMode,
        } = toggleStrictToCustomStructure({ expenseCategories: expenseCategoriesFields.value, expenseHistory })

        form.change('isStrictExpensesMode', newIsStrictExpensesMode)
        form.change('utilitiesExpensesMode', updatedUtilitiesExpensesMode)
        form.change('expenseCategories', updatedExpenseCategories)
        form.change('expenseHistory', updatedExpenseHistory)
        resetUndo()
      }
    },
    [form, expenseHistory, expenseCategoriesFields.value, utilitiesExpensesMode, resetUndo]
  )

  const monthLabel =
    expensePeriod === EXPENSE_HISTORY_TYPE_TITLES.ANNUALIZED_HISTORICAL ? 'Last Month Provided' : 'Month'
  const hasProjectedYearSelected = expenseHistory.some(field => isProjectionPeriod(field.expensePeriod))

  const monthsDisabled = isProjectionOrActualPeriod(expensePeriod)
  const periods = hasProjectedYearSelected ? EXPENSE_PERIODS_WITHOUT_PROJECTED : EXPENSE_PERIOD_VALUES

  const tooltipText =
    "Upon draft submission in Salesforce this subject's expense data will be uploaded to the Bowery Database as an expense comparable."

  return (
    <Layout.VerticalRow sx={{ maxWidth: 1200 }}>
      <Callout content={EXPENSE_HISTORIES_LIMIT_WARNING} variant="warn" />
      <FeatureToggle featureFlag={FEATURE_FLAG_AUTOMATED_EXPENSE_HISTORY}>
        <AutomationContainer form={form} makeUndoable={makeUndoable} isStrictMode={isStrictExpensesMode} />
      </FeatureToggle>
      <Paper>
        <Layout.Grid container="auto-flow / 1fr 1fr 1fr auto" gap={2} placeItems="center stretch" sx={{ mb: 2 }}>
          <Select.Single
            data-qa="expense-period-select"
            name="expensePeriod"
            label="Expense Period"
            options={periods}
          />
          <AutocompleteField
            data-qa="expense-month-input"
            disabled={monthsDisabled}
            label={monthLabel}
            name="expenseMonth"
            options={MONTHS}
          />
          <Input.Numbers
            data-qa="expense-month-year"
            disabled={!expensePeriod}
            label="Year"
            name="expenseYear"
            validate={validateYear}
          />
          <Button
            data-qa="expense-history-new-expense-year-btn"
            disabled={disableAdd(expensePeriod, expenseMonth, expenseYear, form)}
            onClick={makeUndoable(onAddNewColumn)}
            variant="outlined"
          >
            Add Expense Year
          </Button>
        </Layout.Grid>
      </Paper>
      <Paper>
        <Typography gutterBottom variant="h6">
          Expense Information
        </Typography>
        {isSimplifiedExpensesEnabled && (
          <SwitchField
            name="isStrictExpensesMode"
            labelProps={{ label: 'Use only standard Bowery categories' }}
            onChange={changeExpensesMode}
          />
        )}
        <Typography variant="subtitle1">Utility Expenses</Typography>
        <RadioButtonList
          horizontal
          items={UTILITIES_EXPENSES_MODE_OPTIONS}
          name="utilitiesExpensesMode"
          onChange={changeUtilitiesExpensesMode}
          disabled={isStrictExpensesMode}
        />

        <Typography variant="subtitle1">Who provided the expense data for the subject property?</Typography>
        <RadioButtonList horizontal name="expenseDataSource" items={EXPENSE_DATA_SOURCE_OPTIONS} />
        <Stack alignItems="center" direction="row" spacing={1}>
          <Typography variant="subtitle1">Submit Subject Expense Data to Bowery database?</Typography>
          <Tooltip title={tooltipText} placement="right">
            <InfoOutlinedIcon fontSize="small" />
          </Tooltip>
        </Stack>
        <RadioButtonList horizontal name="subjectAsExpenseCompCondition" items={SUBJECT_AS_EXPENSE_OPTIONS} />
      </Paper>
      {isStrictExpensesMode ? (
        <StrictExpenseHistoryTable
          form={form}
          basis={basis}
          setBasis={setBasis}
          undoButtonComponent={UndoButton}
          makeUndoable={makeUndoable}
        />
      ) : (
        <ExpenseHistoryTable
          form={form}
          basis={basis}
          setBasis={setBasis}
          undoButtonComponent={UndoButton}
          makeUndoable={makeUndoable}
          resetUndo={resetUndo}
          expenseCategoriesFields={expenseCategoriesFields}
        />
      )}
      <Paper>
        <NarrativeComponent
          title="Expense History Discussion"
          generatedText={expenseHistoryDiscussion.generate}
          data={expenseHistoryDiscussion.mapDTO(formValues)}
          name="expenseHistoryDiscussion"
          tooltipText={EXPENSE_HISTORY_TOOLTIP}
        />
      </Paper>
    </Layout.VerticalRow>
  )
}

ExpenseHistoryContainer.propTypes = {
  form: PropTypes.object.isRequired,
  reportNumber: PropTypes.string,
}

export const formOptions = {
  heading,
  /*
   * Without it, the form on `Save` would change its values to the ones provided by initialValues on initialization.
   * https://final-form.org/docs/final-form/types/Config#keepdirtyonreinitialize
   */
  keepDirtyOnReinitialize: true,
  mutators: {
    ...arrayMutators,
  },
  decorators: [rowDecorator, columnDecorator, setExpenseDate, defaultDate],
  registeredFields: ['expenseHistory'],
  onPreSave: formValues => {
    const expenseCategories = get(formValues, 'expenseCategories', [])
    const expenseHistory = get(formValues, 'expenseHistory', [])

    const [categories, subcategories] = partition(expenseCategories, expenseCategory => !expenseCategory.parentId)

    const subcategoryMap = new Map()
    subcategories.forEach(({ parentId, ...rest }) => {
      subcategoryMap.set(parentId, [...(subcategoryMap.get(parentId) || []), rest])
    })

    const updatedExpenseCategories = categories.map(({ hasSubcategories, isOpen, ...rest }) => {
      return {
        ...rest,
        subcategories: subcategoryMap.get(rest.id) || [],
      }
    })

    const updatedExpenseHistory = expenseHistory.map(expensePeriodUnit => {
      subcategoryMap.forEach((subcategories, categoryId) => {
        const parentExpense = expensePeriodUnit.expenses.find(({ id }) => id === categoryId)

        if (parentExpense && subcategories?.length) {
          const subcategoriesIds = subcategories.map(({ id }) => id)

          const values = expensePeriodUnit.expenses
            .filter(({ id, value }) => subcategoriesIds.includes(id) && isNumber(value))
            .map(({ value }) => value)

          parentExpense.value = values.length ? sum(values) : null
        }
      })

      return expensePeriodUnit
    })

    return { ...formValues, expenseCategories: updatedExpenseCategories, expenseHistory: updatedExpenseHistory }
  },
}

export default wrapForm(DATA_PATH, formOptions, state => {
  const formData = get(state, `report.reportData.${DATA_PATH.join('.')}`, {})

  return {
    reportNumber: get(state, 'report.reportData.number'),
    initialValues: {
      ...formData,
      expenseCategories: flattenExpenseCategories(get(formData, `expenseCategories`, [])),
    },
  }
})(ExpenseHistoryContainer)
