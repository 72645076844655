import { get, partition } from 'lodash'

import { GENERAL_CONDITIONS } from '../../../../constants/property/description'
import { VALUE_CONCLUSION_TYPES } from '../../../../constants/acas'
import { simplePluralize as pluralize, pluralizeBeVerb } from '../../index'
import { formatList } from '../../../../../client/src/shared/utils/textHelpers'

const mapData = (units, formValues) => {
  const unitsWithNumbers = units.map((unit, index) => {
    const cleanUpUnit = (({ isVacant }) => ({ isVacant }))(unit)
    cleanUpUnit.number = index + 1
    return cleanUpUnit
  })
  const [vacantUnits, occupiedUnits] = partition(unitsWithNumbers, 'isVacant')
  const vacantUnitsLength = vacantUnits.length
  const isVacantUnits = !!vacantUnitsLength
  const noVacantUnits = !vacantUnitsLength

  const vacantUnitNumbers = vacantUnits.map(unit => unit.number)
  const vacantUnitsList = formatList(vacantUnitNumbers)
  const vacantUnitsWord = pluralize('unit', vacantUnitsLength)
  const vacantUnitsVerb = pluralizeBeVerb(vacantUnitsLength)
  const vacantUnitTermsWord = pluralize('term', vacantUnitsLength)
  const occupiedUnitLeasesWord = pluralize('lease', occupiedUnits.length)

  const conclusionType = get(formValues, 'valueConclusionType')
  const valueAsIs = conclusionType === VALUE_CONCLUSION_TYPES.AS_IS
  const mentionRenovations =
    !valueAsIs && get(formValues, 'propertyCondition.value') === GENERAL_CONDITIONS.underRenovation

  return {
    unitsCount: units.length,
    mentionRenovations,
    isVacantUnits,
    noVacantUnits,
    occupiedUnitLeasesWord,
    vacantUnitsWord,
    vacantUnitsList,
    vacantUnitTermsWord,
    vacantUnitsVerb,
  }
}

export const mapDTO = formValues => {
  const { units } = formValues
  return mapData(units, formValues)
}

export const mapDataModel = report => {
  const propertyCondition = get(report, 'buildingDescription.generalCondition')
  const valueConclusionType = get(report, 'valueConclusionType')
  const units = report.commercial?.projectedRentRoll || []
  return mapData(units, {
    propertyCondition,
    valueConclusionType,
  })
}
