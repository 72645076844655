import createDecorator from 'final-form-calculate'

import { getRents } from '../../../../../../../shared/calculations/commercial'
import { isRentPsfMonthly } from '../../../../../../../shared/helpers/commercialIncome'
import { RentTypesUnitsOfMeasure } from '../../../../../../../shared/constants/report/incomeApproach'

export const rentBasisDecorator = createDecorator({
  field: 'rentBasis',
  updates: (value, name, allValues, prevValues) => {
    const rentBasis = allValues.rentBasis
    const prevRentBasis = prevValues.rentBasis
    const changedBetweenPSFBases =
      (rentBasis === RentTypesUnitsOfMeasure.PER_SF_PER_MONTH &&
        prevRentBasis === RentTypesUnitsOfMeasure.PER_SF_PER_YEAR) ||
      (rentBasis === RentTypesUnitsOfMeasure.PER_SF_PER_YEAR &&
        prevRentBasis === RentTypesUnitsOfMeasure.PER_SF_PER_MONTH)

    if (!changedBetweenPSFBases) {
      return {}
    }

    const updatedUnits = allValues.units.map(unit => {
      const rentPsfWasMonthly = isRentPsfMonthly(prevRentBasis)
      const baseRent = rentPsfWasMonthly ? unit.monthlyRentPsf : unit.annualRentPsf
      const rentCalculationInfo = { rentType: rentBasis, baseRent, squareFeet: unit.squareFeet }

      const { annualRent, annualRentPsf, monthlyRent, monthlyRentPsf } = getRents(rentCalculationInfo)

      return {
        ...unit,
        annualRent,
        monthlyRent,
        annualRentPsf,
        monthlyRentPsf,
      }
    })

    return {
      units: [...updatedUnits],
    }
  },
})
