import React, { FC, useCallback, useMemo } from 'react'

import { Typography, Paper, Grid, Button } from '@mui/material'
import { ColumnDataTypeEnum, RowBasedTable } from '@bowery-valuation/ui-components'
import { RowBasedTableColumn, getCustomColumnConfig } from 'client-shared/utils/rowBasedTable'
import { filter, find, get, noop, remove, replace, startCase, sumBy, upperFirst } from 'lodash'
import { formatCurrencyFloat, formatPercentageString } from 'shared/utils/formatters/numberFormatters'
import { IncomeAdjustmentKeys } from 'shared/constants/report/sales/salesAdjustment'
import { SALES_APPROACH_TYPES } from 'shared/constants/salesApproach'
import { PropertyTypes } from 'shared/constants'
import {
  ADJUSTMENT_GROUPS,
  DEFAULT_ADJUSTMENT_LABELS,
  OTHER_ADJUSTMENT_ROW_KEYS,
  getDefaultRows,
} from 'shared/helpers/salesApproach/salesCompAdjustmentConstants'
import { fromPercents } from 'client-shared/utils/numberOperations'
import { getCustomRowNames } from 'shared/helpers/salesApproach/salesCompAdjustments'

import { getTrendAdjustedPricePerBasis } from '../calculations'

import { getCompInformationRows, getParkingRatio } from './constants'
import { AdjustmentName, GroupName } from './types'
import ColumnHeader from './Components/ColumnHeader'

type SalesAdjustmentGridV2Props = {
  form: any
}

export const SalesAdjustmentGridV2: FC<SalesAdjustmentGridV2Props> = ({ form }) => {
  const formValues = form.getState().values
  const {
    unitOfComparison,
    type,
    incomeAdjustmentLevel,
    compAdjustments = [],
    subjectPropertyInformation = {},
    selectedComps = [],
    otherAdjustmentDiscussions,
  } = formValues
  const handleUpdateRow = (row: any) => {
    const { id } = row
    const { change } = form
    const rowIsCustom = id.includes('custom')

    compAdjustments.forEach((compAdjustment: any, index: number) => {
      const { compId, adjustments } = compAdjustment
      let adjustmentToUpdate = find(adjustments, (adjustment: any) => adjustment.name === id)
      if (rowIsCustom) {
        const customIds = id.split('_')
        const groupName = customIds[0]
        const customIndex = customIds[2]
        const customAdjustmentsForGroup = filter(
          adjustments,
          adjustment => adjustment.groupName === groupName && adjustment.custom
        )
        adjustmentToUpdate = customAdjustmentsForGroup[customIndex]
        adjustmentToUpdate.name = row.label

        const newAdjustmentDiscussion = { name: row.label, discussion: {} }
        otherAdjustmentDiscussions.push(newAdjustmentDiscussion)
        change('otherAdjustmentDiscussions', [...otherAdjustmentDiscussions])
      }
      const rawValue = row[compId]
      const numericValue = fromPercents(replace(rawValue, '%', ''))
      adjustmentToUpdate.value = numericValue
    })
    change('compAdjustments', [...compAdjustments])
  }

  const getColumns = useCallback(() => {
    const columns: RowBasedTableColumn[] = [
      {
        name: 'label',
        label: '',
        type: ColumnDataTypeEnum.text,
        minWidth: 350,
        permanent: true,
        align: 'left',
        editable: params => {
          if (params.data.custom) {
            return true
          }
          return false
        },
      },
    ]
    selectedComps.forEach((comp: any) => {
      columns.push({
        name: comp._id,
        label: comp.address?.streetAddress,
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'right',
        editable: params => {
          if (params.data.totalRow || params.data.incomeLevel) {
            return false
          }
          return true
        },
      })
    })
    return columns
  }, [selectedComps])

  const getUneditableColumns = useCallback(() => {
    const columns: RowBasedTableColumn[] = [
      {
        name: 'label',
        label: '',
        type: ColumnDataTypeEnum.text,
        minWidth: 200,
        permanent: true,
        align: 'left',
        editable: false,
      },
      {
        name: 'subject',
        label: 'Subject',
        type: ColumnDataTypeEnum.text,
        minWidth: 150,
        permanent: true,
        align: 'right',
        editable: false,
      },
    ]
    selectedComps.forEach((comp: any) => {
      columns.push({
        name: comp._id,
        // @ts-ignore
        label: <ColumnHeader comp={comp} />,
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'right',
        editable: false,
      })
    })
    return columns
  }, [selectedComps])

  const getAdjustmentLabel = useCallback(
    (adjustment: AdjustmentName) => {
      if (adjustment === OTHER_ADJUSTMENT_ROW_KEYS.stabilization) {
        return incomeAdjustmentLevel === IncomeAdjustmentKeys.income
          ? 'Income Level Adjustment'
          : 'Rent Stabilization Adjustment'
      }
      return DEFAULT_ADJUSTMENT_LABELS[adjustment] || adjustment
    },
    [incomeAdjustmentLevel]
  )

  const getRows = useCallback(
    (groupName: GroupName) => {
      const { propertyType } = subjectPropertyInformation
      const isImprovedSales = type === SALES_APPROACH_TYPES.IMPROVED
      const isCommercialProperty = propertyType === PropertyTypes.COMMERCIAL
      const defaultRows = getDefaultRows(isImprovedSales, isCommercialProperty)[groupName] || []
      if (!compAdjustments.length) {
        return []
      }
      const adjustmentNames = filter(
        compAdjustments[0].adjustments,
        adjustment =>
          adjustment.groupName === groupName && !adjustment.isCustom && defaultRows.includes(adjustment.name)
      )?.map(adjustment => adjustment.name)

      const adjustmentRows: any = []
      adjustmentNames.forEach(adjustment => {
        if (
          adjustment === OTHER_ADJUSTMENT_ROW_KEYS.stabilization &&
          incomeAdjustmentLevel === IncomeAdjustmentKeys.none
        ) {
          return
        }
        const showAction = adjustment === 'commercial' || adjustment === 'averageUnitSize'
        const isIncomeLevelAdjustment =
          incomeAdjustmentLevel === IncomeAdjustmentKeys.income &&
          adjustment === OTHER_ADJUSTMENT_ROW_KEYS.stabilization
        const adjustmentRow: any = {
          readOnly: false,
          suppressMovable: true,
          type: ColumnDataTypeEnum.text,
          id: adjustment,
          custom: false,
          label: getAdjustmentLabel(adjustment),
          rowDef: { hideAction: !showAction },
          incomeLevel: isIncomeLevelAdjustment,
        }
        compAdjustments.forEach((compAdjustment: any) => {
          const { adjustments, compId } = compAdjustment
          const groupAdjustments = filter(adjustments, adj => adj.groupName === groupName)
          const adjustmentValue =
            find(groupAdjustments, groupAdjustment => groupAdjustment.name === adjustment)?.value || 0
          adjustmentRow[compId] = formatPercentageString(adjustmentValue)
        })
        adjustmentRows.push(adjustmentRow)
      })

      const customAdjustments = filter(
        compAdjustments[0].adjustments,
        adjustment => adjustment.groupName === groupName && adjustment.custom
      )?.map(adjustment => adjustment.name)
      customAdjustments.forEach((customAdjustment, index) => {
        const adjustmentRow: any = {
          readOnly: false,
          suppressMovable: true,
          permanent: false,
          type: ColumnDataTypeEnum.text,
          id: `${groupName}_custom_${index}`,
          custom: true,
          label: customAdjustment,
          rowDef: { hideAction: false },
        }
        compAdjustments.forEach((compAdjustment: any) => {
          const { adjustments, compId } = compAdjustment
          const groupAdjustments = filter(adjustments, adj => adj.groupName === groupName)
          const adjustmentValue =
            find(groupAdjustments, groupAdjustment => groupAdjustment.name === customAdjustment)?.value || 0
          adjustmentRow[compId] = formatPercentageString(adjustmentValue)
        })
        adjustmentRows.push(adjustmentRow)
      })
      return adjustmentRows
    },
    [compAdjustments, getAdjustmentLabel, subjectPropertyInformation, type, incomeAdjustmentLevel]
  )

  const getNewAdjustmentName = (rowNames: string[]) => {
    let defaultName = `Other Adjustment`
    const rowsSet = new Set(rowNames)
    let index = 1
    while (rowsSet.has(defaultName)) {
      defaultName = `Other Adjustment ${index}`
      index += 1
    }
    return defaultName
  }

  const addCustomAdjustment = () => {
    const { batch, change } = form

    const rowNamesInGroup = getCustomRowNames(compAdjustments, ADJUSTMENT_GROUPS.OTHER)
    const addedRowName = getNewAdjustmentName(rowNamesInGroup)

    batch(() => {
      for (const [, compAdjustment] of compAdjustments.entries()) {
        compAdjustment.adjustments.push({
          name: addedRowName,
          value: 0,
          groupName: ADJUSTMENT_GROUPS.OTHER,
          custom: true,
        })
      }
      change('compAdjustments', [...compAdjustments])
      const newAdjustmentDiscussion = { name: addedRowName, discussion: {} }
      otherAdjustmentDiscussions.push(newAdjustmentDiscussion)
      change('otherAdjustmentDiscussions', [...otherAdjustmentDiscussions])
    })
  }

  const handleDeleteRow = (rowId: any) => {
    const { change } = form
    let adjustmentNameToRemove = rowId
    const isCustomRow = rowId.includes('custom')
    if (isCustomRow) {
      const customIds = rowId.split('_')
      const customIndex = customIds[2]

      const { adjustments } = compAdjustments[0]
      const customAdjustments = filter(adjustments, adjustment => adjustment.custom)
      adjustmentNameToRemove = customAdjustments[customIndex]?.name
    }
    compAdjustments.forEach((compAdjustment: any, index: number) => {
      remove(compAdjustment.adjustments, (adjustment: any) => adjustment.name === adjustmentNameToRemove)
    })
    change('compAdjustments', [...compAdjustments])
  }

  const totalRows = useMemo(() => {
    const totalsByCompId: any[] = []
    const totalRowsByType: any = {}

    compAdjustments.forEach((compAdjustment: any) => {
      const { compId, adjustments } = compAdjustment
      const selectedComp = find(selectedComps, comp => comp._id === compId)
      const trendedPricePerBasis = getTrendAdjustedPricePerBasis(selectedComp, adjustments, unitOfComparison)

      const marketAdjustments = filter(adjustments, adjustment => adjustment.groupName === 'market')
      const marketTotal = sumBy(marketAdjustments, adjustment => adjustment.value)

      const locationAdjustments = filter(adjustments, adjustment => adjustment.groupName === 'location')
      const locationTotal = sumBy(locationAdjustments, adjustment => adjustment.value)

      const utilityAdjustments = filter(adjustments, adjustment => adjustment.groupName === 'utility')
      const utilityTotal = sumBy(utilityAdjustments, adjustment => adjustment.value)

      const otherAdjustments = filter(adjustments, adjustment => adjustment.groupName === 'other')
      const otherTotal = sumBy(otherAdjustments, adjustment => adjustment.value)

      const netTotal = utilityTotal + locationTotal + otherTotal
      const adjustedPricePerBasis = trendedPricePerBasis * (1 + netTotal)
      totalsByCompId.push({
        compId,
        market: marketTotal,
        utility: utilityTotal,
        location: locationTotal,
        other: otherTotal,
        trendedPricePerBasis,
        total: netTotal,
        adjustedPricePerBasis,
      })
    })
    const groupNames = [
      'market',
      'trendedPricePerBasis',
      'location',
      'utility',
      'other',
      'total',
      'adjustedPricePerBasis',
    ]
    groupNames.forEach((group: string) => {
      let label = group
      let formatter = formatPercentageString
      switch (group) {
        case 'trendedPricePerBasis':
        case 'adjustedPricePerBasis':
          label = startCase(group)
          formatter = formatCurrencyFloat
          break
        case 'total':
          label = upperFirst(group)
          formatter = formatPercentageString
          break
        default:
          label = `${upperFirst(group)} Total`
          formatter = formatPercentageString
      }
      const totalRow: any = {
        readOnly: true,
        suppressMovable: true,
        permanent: false,
        type: ColumnDataTypeEnum.text,
        id: `${group}Total`,
        label,
        // TODO: make total row sticky
        rowDef: { hideAction: true, summary: true },
        totalRow: true,
      }

      totalsByCompId.forEach(total => {
        const { compId } = total
        totalRow[compId] = formatter(total[group])
      })

      totalRowsByType[group] = totalRow
    })
    return totalRowsByType
  }, [compAdjustments, selectedComps, unitOfComparison])

  const compInformationRows = useMemo(() => {
    const rows = getCompInformationRows(subjectPropertyInformation, unitOfComparison)
    rows.map((row: any) => {
      const { formatter } = row
      selectedComps.forEach((comp: any) => {
        const { id } = comp
        let value = get(comp, row.id)
        if (row.id === 'parkingRatio') {
          value = getParkingRatio(comp.propertyInformation, unitOfComparison)
        }
        row[id] = formatter ? formatter(value) : value
      })

      return row
    })
    return rows
  }, [selectedComps, subjectPropertyInformation, unitOfComparison])

  const columns: RowBasedTableColumn[] = useMemo(() => getColumns(), [getColumns])
  const uneditableColumns: RowBasedTableColumn[] = useMemo(() => getUneditableColumns(), [getUneditableColumns])
  const marketRows = useMemo(() => getRows('market'), [getRows])
  const locationAdjustmentRows = useMemo(() => getRows('location'), [getRows])
  const utilityRows = useMemo(() => getRows('utility'), [getRows])
  const otherRows = useMemo(() => getRows('other'), [getRows])

  const marketAdjustmentRows = [...marketRows, totalRows.market, totalRows.trendedPricePerBasis]
  const tableRows = useMemo(() => {
    const allAdjustments = [
      ...locationAdjustmentRows,
      ...utilityRows,
      ...otherRows,
      totalRows.total,
      totalRows.adjustedPricePerBasis,
    ]
    return allAdjustments
  }, [locationAdjustmentRows, utilityRows, otherRows, totalRows.adjustedPricePerBasis, totalRows.total])

  return (
    <Paper>
      <Grid container spacing={2}>
        <Grid item container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">Sales Adjustment Grid</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item sx={{ paddingBottom: '12px' }}>
            <Typography variant="subtitle2">Market Adjustments</Typography>
          </Grid>
          <RowBasedTable
            id="market-adjustments-table"
            columns={columns}
            rows={marketAdjustmentRows}
            onRowUpdate={handleUpdateRow}
            onManyRowsUpdate={noop}
            getCustomColumnConfig={getCustomColumnConfig}
            hideIndexColumn
            onColumnDragEnd={noop}
            onRowsDragEnd={noop}
            onColumnDelete={noop}
            onColumnUpdate={noop}
            onRowDelete={noop}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid item sx={{ paddingBottom: '12px' }}>
            <Typography variant="subtitle2">Comp Information</Typography>
          </Grid>
          <RowBasedTable
            id="comp-information-table"
            columns={uneditableColumns}
            rows={compInformationRows}
            onRowUpdate={noop}
            onManyRowsUpdate={noop}
            getCustomColumnConfig={getCustomColumnConfig}
            hideIndexColumn
            onColumnDragEnd={noop}
            onRowsDragEnd={noop}
            onColumnDelete={noop}
            onColumnUpdate={noop}
            onRowDelete={noop}
            disableVirtualization
          />
        </Grid>
        <Grid item xs={12}>
          <Grid item container justifyContent="space-between">
            <Grid item>
              <Typography variant="subtitle2">Adjustments</Typography>
            </Grid>
            <Grid item>
              <Button variant="text" onClick={addCustomAdjustment}>
                Add Adjustment
              </Button>
            </Grid>
          </Grid>
          <RowBasedTable
            id="physical-adjustments-table"
            columns={columns}
            rows={tableRows}
            onRowUpdate={handleUpdateRow}
            onManyRowsUpdate={noop}
            getCustomColumnConfig={getCustomColumnConfig}
            hideIndexColumn
            onColumnDragEnd={noop}
            onRowsDragEnd={noop}
            onColumnDelete={noop}
            onColumnUpdate={noop}
            onRowDelete={handleDeleteRow}
            disableVirtualization
          />
        </Grid>
      </Grid>
    </Paper>
  )
}
