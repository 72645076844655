import React from 'react'
import PropTypes from 'prop-types'
import { compose, pure } from 'recompose'
import { Typography } from '@mui/material'
import { withStyles } from '@mui/styles'

import { longDateFormat } from 'client-shared/utils/dateHelper'
import { UneditableSection } from 'client-shared/components'

import { PROPERTY_TYPES_PROPER_CASE } from 'shared/constants'

import { PreviewSection } from '../components'

import AppraisersList from './AppraisersList'

const styles = {
  clientInformationSection: {
    width: 'fit-content',
    minWidth: 380,
  },
  datesOfValueSection: {
    width: 380,
  },
  appraisalReportSection: {
    width: 280,
  },
  appraisersSection: {
    width: 'fit-content',
  },
  appraisersLinkContent: {
    opacity: 1,
  },
}

const Edit = ({
  classes,
  city,
  reportClients,
  address,
  appraisers,
  datesOfValue,
  propertyType,
  dateSectionName,
  applicationNumbers,
  reportSettingsLink,
  organizationClientLink,
  buildingName,
  buildingDescriptor,
  getClients,
  hideClientName,
}) => {
  const { dateOfFinalValueAsIs, dateOfFinalValueAsStabilized, dateOfFinalValueAsComplete } = datesOfValue
  const showProspectiveMarketDates = dateOfFinalValueAsStabilized || dateOfFinalValueAsComplete

  return (
    <>
      <PreviewSection name="APPRAISAL REPORT" classes={{ root: classes.appraisalReportSection }}>
        <UneditableSection formName="Property Summary" name="Address" link="property-summary">
          {buildingName && (
            <Typography variant="body1" gutterBottom data-qa="buildingName">
              {buildingName}
            </Typography>
          )}
          {address && (
            <Typography variant="body1" gutterBottom data-qa="address">
              {address}
            </Typography>
          )}
          {city && (
            <Typography variant="body1" gutterBottom data-qa="city">
              {city}
            </Typography>
          )}
        </UneditableSection>
        {applicationNumbers && (
          <UneditableSection formName="Client" name="NYCB Application No." link="client">
            <Typography variant="body1" gutterBottom data-qa="application-number">
              {applicationNumbers.join(', ')}
            </Typography>
          </UneditableSection>
        )}
      </PreviewSection>
      <PreviewSection name="BUILDING" classes={{ root: classes.clientInformationSection }}>
        <UneditableSection formName="Settings & Export" name="Property Type" link="review-and-export">
          <Typography variant="body1" gutterBottom data-qa="propertyType">
            {PROPERTY_TYPES_PROPER_CASE[propertyType]}
          </Typography>
        </UneditableSection>
        <UneditableSection formName="Property Summary" name="Building Descriptor" link="property-summary">
          <Typography variant="body1" gutterBottom data-qa="buildingDescriptor">
            {buildingDescriptor}
          </Typography>
        </UneditableSection>
      </PreviewSection>
      <PreviewSection name="REQUESTED BY" classes={{ root: classes.clientInformationSection }}>
        <UneditableSection
          formName="Organization Clients"
          name="Client Information"
          link={`../../${organizationClientLink}`}
        >
          {getClients(reportClients, hideClientName)}
        </UneditableSection>
      </PreviewSection>
      <PreviewSection name={dateSectionName} classes={{ root: classes.datesOfValueSection }}>
        {showProspectiveMarketDates && (
          <UneditableSection
            formName="Cap Rate Conclusion"
            name="Prospective Market Value Dates"
            link="cap-rate-conclusion"
          >
            {dateOfFinalValueAsStabilized && (
              <Typography variant="body1" gutterBottom data-qa="as-stabilized">
                As Stabilized: {longDateFormat(dateOfFinalValueAsStabilized)}
              </Typography>
            )}
            {dateOfFinalValueAsComplete && (
              <Typography variant="body1" gutterBottom data-qa="as-complete">
                As Complete: {longDateFormat(dateOfFinalValueAsComplete)}
              </Typography>
            )}
          </UneditableSection>
        )}
        {dateOfFinalValueAsIs && (
          <UneditableSection formName="Cap Rate Conclusion" name="As Is Market Value Date" link="cap-rate-conclusion">
            <Typography variant="body1" gutterBottom data-qa="as-is">
              As Is: {longDateFormat(dateOfFinalValueAsIs)}
            </Typography>
          </UneditableSection>
        )}
      </PreviewSection>
      <PreviewSection name="PREPARED BY" classes={{ root: classes.appraisersSection }}>
        <UneditableSection
          formName="Appraiser"
          name="Appraisers"
          classes={{ content: classes.appraisersLinkContent }}
          link="appraisers"
        >
          <AppraisersList appraisers={appraisers} />
        </UneditableSection>
      </PreviewSection>
    </>
  )
}

Edit.propTypes = {
  applicationNumbers: PropTypes.array,
  address: PropTypes.string,
  city: PropTypes.string.isRequired,
  reportClients: [
    PropTypes.shape({
      abbr: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      client: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      title: PropTypes.string,
    }),
  ],
  datesOfValue: PropTypes.shape({
    dateOfFinalValueAsIs: PropTypes.string,
    dateOfFinalValueAsStabilized: PropTypes.string,
    dateOfFinalValueAsComplete: PropTypes.string,
  }),
  organizationClientLink: PropTypes.string.isRequired,
  dateSectionName: PropTypes.string.isRequired,
}

Edit.defaultProps = {
  address: null,
  applicationNumbers: [],
  reportClients: [],
  datesOfValue: {},
}

export default compose(withStyles(styles), pure)(Edit)
