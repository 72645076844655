import { isNil } from 'lodash'

import { RentTypesUnitsOfMeasure, TIME_PERIODS } from '../constants/report/incomeApproach'
import { divide, isValidDivisor } from '../utils/numberOperations'
import { isRentPsfMonthly } from '../helpers/commercialIncome'

const isRentPerSF = rentBasis => {
  return [RentTypesUnitsOfMeasure.PER_SF_PER_MONTH, RentTypesUnitsOfMeasure.PER_SF_PER_YEAR].includes(rentBasis)
}

export const convertTimePeriod = (rent, fromTimePeriod, toTimePeriod) => {
  if (isNil(rent)) {
    return null
  }

  if (fromTimePeriod === TIME_PERIODS.MONTHLY && toTimePeriod === TIME_PERIODS.ANNUALLY) {
    return rent * 12
  }
  if (fromTimePeriod === TIME_PERIODS.ANNUALLY && toTimePeriod === TIME_PERIODS.MONTHLY) {
    return rent / 12
  }

  return rent
}

export const getAnnualRent = ({ rentType, baseRent, squareFeet } = {}) => {
  if (rentType === RentTypesUnitsOfMeasure.ANNUAL) {
    return baseRent
  }
  if (rentType === RentTypesUnitsOfMeasure.MONTHLY) {
    return convertTimePeriod(baseRent, TIME_PERIODS.MONTHLY, TIME_PERIODS.ANNUALLY)
  }

  const rentPsf = baseRent * (squareFeet || 1)
  if (rentType === RentTypesUnitsOfMeasure.PER_SF_PER_YEAR) {
    return rentPsf
  }
  if (rentType === RentTypesUnitsOfMeasure.PER_SF_PER_MONTH) {
    return convertTimePeriod(rentPsf, TIME_PERIODS.MONTHLY, TIME_PERIODS.ANNUALLY)
  }
  return 0
}

export const getAnnualRentPerSF = ({ rentType, baseRent, squareFeet } = {}) => {
  const totalAnnualRent = getAnnualRent({ rentType, baseRent, squareFeet })
  const annualRentPsf = divide(totalAnnualRent, squareFeet)
  return annualRentPsf
}

export const getRentPerSF = (
  { rentType, baseRent, squareFeet } = {},
  rentRollRentBasis = RentTypesUnitsOfMeasure.PER_SF_PER_YEAR
) => {
  const toTimePeriod = isRentPsfMonthly(rentRollRentBasis) ? TIME_PERIODS.MONTHLY : TIME_PERIODS.ANNUALLY
  const rentIsPerSF = isRentPerSF(rentType)

  if (rentIsPerSF) {
    const fromTimePeriod = isRentPsfMonthly(rentType) ? TIME_PERIODS.MONTHLY : TIME_PERIODS.ANNUALLY
    return convertTimePeriod(baseRent, fromTimePeriod, toTimePeriod)
  }

  const annualRentPsf = getAnnualRentPerSF({ rentType, baseRent, squareFeet })
  return convertTimePeriod(annualRentPsf, TIME_PERIODS.ANNUALLY, toTimePeriod)
}

export const getRents = ({ rentType, baseRent, squareFeet } = {}) => {
  const rentCalculationInfo = { rentType, baseRent, squareFeet }
  const annualRent = getAnnualRent(rentCalculationInfo)
  const monthlyRent = convertTimePeriod(annualRent, TIME_PERIODS.ANNUALLY, TIME_PERIODS.MONTHLY)
  const annualRentPsf = getRentPerSF(rentCalculationInfo, RentTypesUnitsOfMeasure.PER_SF_PER_YEAR)
  const monthlyRentPsf = getRentPerSF(rentCalculationInfo, RentTypesUnitsOfMeasure.PER_SF_PER_MONTH)

  if (isValidDivisor(squareFeet)) {
    return {
      annualRent,
      annualRentPsf,
      monthlyRent,
      monthlyRentPsf,
    }
  }

  const rentIsPerSF = isRentPerSF(rentType)
  if (rentIsPerSF) {
    return {
      annualRent: null,
      annualRentPsf,
      monthlyRent: null,
      monthlyRentPsf,
    }
  }

  return {
    annualRent,
    annualRentPsf: null,
    monthlyRent,
    monthlyRentPsf: null,
  }
}

export const getAppraiserForecastGrossReimbursement = (appraisersForecast = 0, grossReimbursementPercent = 0) => {
  if (appraisersForecast && grossReimbursementPercent) {
    const total = appraisersForecast * (grossReimbursementPercent / 100)
    return total < 0 ? 0 : total
  }
  return 0
}
